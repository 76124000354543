import React, {useEffect, useState, useContext} from 'react'
import UiInputText from "../components/Form/UiInputText";
import Form from "../components/Form/Form";
import {
    useForm,
    FormProvider,
    useWatch,
    UseFormReturn,
    FieldValues,
    UseFieldArrayReturn
} from "react-hook-form";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import UiInputSuggest from "../components/Form/UiInputSuggest";
import UiGridForm from "../components/Form/UiGridForm";
import UiInputList from "../components/Form/UiInputList";
import UiInputDate from "../components/Form/UiInputDate";
import UiInputTextarea from "../components/Form/UiInputTextarea";
import UiInputNumber from "../components/Form/UiInputNumber";
import {KitDetail} from "./KitDetail";
import {get, getExtruturaList, getOrcamentoTipoCalculoList} from "../services/ApiService";
import VendedorForm from "./VendedorForm";
import AuthProvider from "../services/AuthProvider";
import UiSelectPicker from "../components/Form/UiSelectPicker";

export const FormContext = React.createContext<any>({});

const estruturaListBase: any = getExtruturaList();
const estruturaList = Object.keys(estruturaListBase).map((key) => {
    return { id: key, title: estruturaListBase[key] };
})

const orcamentoTipoCalculoListBase: any = getOrcamentoTipoCalculoList();
const orcamentoTipoCalculoList = Object.keys(orcamentoTipoCalculoListBase).map((key) => {
    return { id: key, title: orcamentoTipoCalculoListBase[key] };
})

const incidenciaListBase: any = {
    "3.36": 'Leste / Oeste - 3.36',
    "3.80": 'Norte - 3.8',
};
const incidenciaList = Object.keys(incidenciaListBase).map((key) => {
    return { id: parseFloat(key).toFixed(2), title: incidenciaListBase[key] };
})

const coeficienteConsumoExtra = 1.10;

function ConsumoMedio({ consumoMedio, ...rest }: any) {
    return <div { ...rest }>
        <div className="consumo-medio">
            <span>Consumo médio: <b>{ Math.ceil(consumoMedio) }</b> kWh</span>
            <span>Recomendado kit com <b>{ Math.round(consumoMedio * coeficienteConsumoExtra) }</b> kWh de geração estimada</span>
        </div>
    </div>;
}

const doFetchConsumo = (uc: any, cpfCnpj: any, setValue: (value: any) => void) => {
    get(`/?route=celesc&action=informarConsumo&pdf&payload=${JSON.stringify({
        nrUC: uc,
        nrDocumento: cpfCnpj
    })}`)
        .then((response: any) => {
            if (response.status == 200) {
                const data = response.data;
                setValue(data);
            } else {
                alert('Falha ao carregar o consumo');
            }
        })
        .catch((err) => {
            alert('Falha ao carregar o consumo: ' + err);
        })
}

const UcLinkComponent = (props: any) => {
    const { watch, getValues } = props.formContext;

    const allData = watch();
    const thisData = getValues(props.name);
    const cpfCnpjParentName = props.name.replace('.uc', '.cpfCnpj');
    let cpfCnpjParent = getValues(cpfCnpjParentName) ?? '';
    cpfCnpjParent = cpfCnpjParent.replace(/\D/g, '');

    let cpfCnpj = allData?.cliente?.cpfCnpj?.replace(/\D/g, '');
    if(cpfCnpjParent && cpfCnpjParent.length > 0){
        cpfCnpj = cpfCnpjParent;
    }
    return <>
        {
            thisData && cpfCnpj &&
            <a className="link-element" href={`https://api.argonsolar.com.br/?route=celesc&action=emitirPdf&pdf&payload=${JSON.stringify({
                nrUC: thisData,
                nrDocumento: cpfCnpj
            })}`} target="_blank">Ver fatura</a>
        }
    </>
}

const ConsumoLinkComponent = (props: any) => {
    const { watch, getValues, setValue } = props.formContext;
    const base = props.name.split('.').slice(0, -1).join('.');
    const allData = watch();
    const uc = getValues(base + '.uc');
    const [ isLoading, setIsLoading ] = useState(false);

    const cpfCnpjParentName = base + '.cpfCnpj';
    let cpfCnpjParent = getValues(cpfCnpjParentName) ?? '';
    cpfCnpjParent = cpfCnpjParent.replace(/\D/g, '');

    let cpfCnpj = allData?.cliente?.cpfCnpj?.replace(/\D/g, '');
    if(cpfCnpjParent && cpfCnpjParent.length > 0){
        cpfCnpj = cpfCnpjParent;
    }

    return <>
        {
            uc && cpfCnpj &&
            <button type="button" onClick={() => {
                setIsLoading(true);
                doFetchConsumo(uc, cpfCnpj, (value) => {
                    setIsLoading(false);
                    setValue(base + '.dataInicial', value?.dataInicial);
                    setValue(props.name, value?.consumo);
                })
            }} className="btn btn-primary btn-xs">{ isLoading ? `Carregando...` : `Carregar da Celesc` }</button>
        }
    </>
}

export default function OrcamentoForm(props: any){
    const { user } = useContext<any>(AuthProvider);

    if(props.data && props.data.ucList){
        if(!Array.isArray(props.data.ucList)) {
            var newList = Object.keys(props.data.ucList).map((itemIndex) => {
                let item = props.data.ucList[itemIndex];
                if (!item.uc) {
                    item.uc = itemIndex;
                    let firstDate: any = null;
                    let newConsumoList: any = [];
                    Object.keys(item.consumo).map((consumoDate: any) => {
                        const consumoItem = item.consumo[consumoDate];
                        if (!firstDate) {
                            firstDate = consumoDate;
                        }
                        newConsumoList.push(consumoItem);
                    })
                    item.dataInicial = firstDate.substring(5, 7) + '/' + firstDate.substring(0, 4);
                    item.consumo = newConsumoList.join(',')
                }
                return item;
            });
            props.data.ucList = newList;
        }
    }

    if(props.data){
        if(!props.data.precoVendaBase){
            props.data.precoVendaBase = props.data.precoVenda;
        }
        if(!props.data.descontoVendedor){
            props.data.descontoVendedor = 0;
        }
    }

    const methods = useForm({
        defaultValues: props.data
    });
    const { handleSubmit, watch, control, setValue } = methods;
    const ligacaoList = [
        { id: 1, title: 'Monofasica' },
        { id: 2, title: 'Bifasica' },
        { id: 3, title: 'Trifasica' },
    ];
    const allData = watch();
    const onSelectKit = (data: any) => {
        console.log(data);
        if(data){
            if(!allData.precoVendaBase || allData.precoVendaBase == '' || allData.precoVendaBase < 1){
            //if(!allData.precoVendaBase || confirm('Definir preço sugerido?')){

                let precoSugerido = data.preco_venda;
                let milhares = Math.round(precoSugerido / 1000) * 1000;
                let centenas = precoSugerido - milhares;
                if(centenas > 600){
                    centenas = 900;
                }else if(centenas > 400 && centenas < 600){
                    centenas = 500;
                }else{
                    centenas = 900;
                    milhares = milhares - 1000;
                }
                precoSugerido = milhares + centenas;

                setValue('precoVendaBase', precoSugerido);
                setValue('descontoVendedor', 0);
            }
            if(data.kit_estrutura){
                setValue('estrutura', data.kit_estrutura);
            }
        }
    }

    if(!allData.incidenciaSolar){
        setValue('incidenciaSolar', 3.36);
    }

    const ucList = useWatch({
        control,
        name: "ucList",
    });

    // const descontoVendedor = watch('descontoVendedor');
    // useEffect(() => {
    //     if(allData.precoVendaBase && allData.descontoVendedor !== null) {
    //         setValue('precoVenda', allData.precoVendaBase - allData.descontoVendedor);
    //     }
    // }, [descontoVendedor])

    useEffect(() => {
        if(!allData.tipo){
            allData.tipo = 1;
        }
        if(!allData.tipoCalculo){
            allData.tipoCalculo = 1;
        }
    }, [allData.tipo, allData.tipoCalculo])

    const precoVendaBase = watch('precoVendaBase');
    useEffect(() => {
        if(allData.precoVendaBase) {
            setValue('precoVenda', (allData.precoVendaBase ?? 0)); // - (allData.descontoVendedor ?? 0)
            setValue('precoVenda', (allData.precoVendaBase ?? 0)); // - (allData.descontoVendedor ?? 0)
        }

        if(allData.kit?.estrutura && !allData.estrutura) {
            setValue('estrutura', allData.kit.estrutura);
        }
    }, [precoVendaBase])

    let consumoMedio = 0;
    let consumoMedioSemGeradora = 0;
    if(ucList && Array.isArray(ucList)) {
        try {
            ucList.map((item: any, index) => {
                if (item.consumo) {
                    const consumo = item.consumo.split(',').filter((n: any) => n);
                    const sum = consumo.reduce((a: any, b: any) => (a * 1) + (b * 1), 0);
                    const avg = (sum / consumo.length) || 0;
                    item.consumoMedio = avg;
                    consumoMedio += avg;
                    if(index > 0) {
                        consumoMedioSemGeradora += avg;
                    }
                }else{
                    item.consumoMedio = 0;
                    item.porcentagemGeral = 0;
                    item.porcentagemSobra = 0;
                }
            });
            consumoMedio = (consumoMedio);
            consumoMedioSemGeradora = (consumoMedioSemGeradora);

            ucList.map((item: any, index) => {
                if (item.consumoMedio) {
                    item.porcentagemGeral = (item.consumoMedio / consumoMedio);
                    item.porcentagemSobra = 0;
                    if(consumoMedioSemGeradora > 0 && index > 0) {
                        item.porcentagemSobra = (item.consumoMedio / consumoMedioSemGeradora);
                    }
                }
            });
        } catch (err) {}
    }

    return (
        <Modal backdrop="static" centered size="xl" height={400} show={true} animation={false} onHide={props.triggerClose}>
            <FormProvider {...methods} >
                <Form onSubmit={handleSubmit(props.triggerSubmit)} methods={methods}>
                    <Modal.Body>
                        <div className="row">
                            <UiInputList size={2} name="tipoCalculo" list={orcamentoTipoCalculoList} idField="id" titleField="title" label="Tipo de Orçamento" />
                            <UiInputSuggest size={3} router="vendedor" idField="id" titleField="nome" name="vendedor" label="Vendedor" formComponent={VendedorForm}/>
                            <UiInputSuggest size={3} router="cliente" idField="id" titleField="nome" name="cliente" label="Cliente"/>
                            <UiInputList size={2} name="estrutura" list={estruturaList} idField="id" titleField="title" label="Estrutura" />
                            <UiSelectPicker size={2} name="tag" label="Tags"
                                            extraDataOnCreate={{ tipo: 2 }}
                                            extraDataOnFilter={{
                                                "name": 'tipo',
                                                "v": '2',
                                                "v2": null,
                                                "op": '='
                                            }}
                                            router="tag" idField="id" titleField="titulo"/>
                        </div>

                        <UiGridForm name="ucList" label="Unidades" extraElements={(placeholder: string, formContext: UseFormReturn<FieldValues, any>, index: number, fieldsArray: UseFieldArrayReturn<FieldValues, any, "id">) => {
                            let porcentagemGeral = formContext.watch(placeholder + '.porcentagemGeral');
                            let porcentagemSobra = formContext.watch(placeholder + '.porcentagemSobra');
                            let returnElements = [];
                            if(porcentagemGeral) {
                                returnElements.push(
                                    <div className="percentualSobra">
                                        {(porcentagemGeral*100).toFixed(2)}% média geral<br />
                                        { porcentagemSobra ? (<><b>{(porcentagemSobra*100).toFixed(2)}%</b> média tirando a geradora</>) : '' }
                                    </div>
                                );
                            }
                            if(index == 0){
                                returnElements.push(
                                    <div className="isGeradora">
                                        Unidade Geradora
                                    </div>
                                );
                            }else{
                                returnElements.push(
                                    <button type="button" className="btn btn-secondary btn-xs btn-geradora" onClick={() => fieldsArray.move(index, 0)}>Tornar geradora</button>
                                );
                            }

                            return returnElements;
                        }}>
                            <UiInputText size={3} name="uc" label="UC" linkComponent={UcLinkComponent}/>
                            <UiInputList size={3} name="ligacao" list={ligacaoList} idField="id" titleField="title" label="Ligação" />
                            <UiInputText size={3} name="cpfCnpj" label="CPF/CNPJ"/>
                            {/*<UiInputText size={3} name="extras" label="Extras"/>*/}
                            <UiInputDate view="month"
                                         dateFormat="mm/yy"
                                         yearRange="2018:2030" size={3} name="dataInicial" label="Data Inicial"/>
                            <UiInputTextarea linkComponent={ConsumoLinkComponent} size={12} name="consumo" label={`Consumo`} onKeyPress={(e: any) => {
                                if(e.key === 'Enter')
                                    e.preventDefault()
                            }}/>
                        </UiGridForm>

                        <div className="row">
                            <ConsumoMedio className="col-sm-12 consumo-medio-container" consumoMedio={consumoMedio} />
                            <UiInputList size={2} name="incidenciaSolar" list={incidenciaList} idField="id" titleField="title" label="Orientação" />
                            <UiInputSuggest size={4}
                                            initialFilter={{
                                                geracao_garantida: {
                                                    type: 'greaterThan',
                                                    filter: Math.round(consumoMedio * coeficienteConsumoExtra)
                                                },
                                                kit_estrutura: {
                                                    type: String(allData.estrutura),
                                                    filter: ''
                                                },
                                                kit_disponivel: {
                                                    type: 'equals',
                                                    filter: '1'
                                                }
                                            }}
                                            extraData={{
                                                incidenciaSolar: allData?.incidenciaSolar ?? 3.8,
                                                consumoMedio: consumoMedio
                                            }}
                                            onSelect={onSelectKit} router="kit" idField="id" titleField="referencia" name="kit" label="Kit"/>

                            <UiInputNumber size={2} step={100} readOnly={user.acesso != 1} name="precoVendaBase" label="Preço base"/>
                            <UiInputNumber size={2} step={100} name="descontoVendedor" label="Desconto do Vendedor"/>
                            <UiInputNumber size={2} step={100} readOnly={true} name="precoVenda" label="Preço de Venda"/>

                        </div>
                        { allData?.kit?.id && <>
                            <KitDetail consumoMedio={consumoMedio} kit={allData.kit} descontoVendedor={allData.descontoVendedor} precoVenda={allData.precoVendaBase} data={allData} />
                        </> }

                        {/*<pre>{JSON.stringify(allData, null, 2)}</pre>*/}
                    </Modal.Body>

                    <Modal.Footer>
                        {/*<UiInputCheckbox size={2} name="config.extTarifario" label="Exibir observação do "/>*/}
                        <Button variant="secondary" onClick={props.triggerClose}>Fechar</Button>
                        <Button variant="primary" type="submit">Salvar</Button>
                        <Button variant="primary" onClick={() => { props.triggerSubmitAsNew(allData) }}>Salvar como novo</Button>
                    </Modal.Footer>
                </Form>
            </FormProvider>
        </Modal>
    )
}