import React, { useContext, useState, useRef, useEffect } from 'react';
import AuthProvider from "../services/AuthProvider";
import {LogIn} from 'react-feather';

import Form from "../components/Form/Form";
import {useForm, FormProvider} from "react-hook-form";
import {ReactComponent as Logo} from '../assets/logo.svg';
import Button from 'react-bootstrap/Button';
import {Toast} from "primereact/toast";

import './Login.scss'
import UiInputText from "./Form/UiInputText";
import Overlay from "./Overlay";

const LoginPage = () => {
    const { triggerLogin, initLogin } = useContext(AuthProvider);
    const [ isLoading, setIsLoading ] = useState(false);
    const methods = useForm({
        defaultValues: {}
    });
    const { register, handleSubmit, watch, setValue } = methods;
    const toast = useRef(null);
    const toastAlert = (payload) => {
        // @ts-ignore
        toast.current.show(payload);
    }
    const allData = watch();
    const onSubmit = () => {
        setIsLoading(true);
        triggerLogin(allData, (response) => {
            toastAlert({
                severity: 'success',
                summary: 'Sucesso',
                detail: response.message,
                life: 3000
            });
        }, (response) => {
            toastAlert({
                severity: 'error',
                summary: 'Erro!',
                detail: response.message,
                life: 3000
            });

            setIsLoading(false);
        })
    }

    useEffect(() => {
        setIsLoading(true);
        initLogin(() => {
            setIsLoading(false);
        }, () => {
            setIsLoading(false);
        });
    }, []);


    return <div className="page-login">

        <Overlay show={isLoading} />
        <Toast ref={toast} />

        <div className="logo">
            <Logo />
            <div className="logo-typo">
                <b>Argon</b><br/>Solar
            </div>
        </div>

        <section>

            <h1>Identifique-se</h1>

            <FormProvider {...methods} >
                <Form onSubmit={handleSubmit(onSubmit)} methods={methods}>
                    <UiInputText labelSize={2} name="login" label="Login"/>
                    <UiInputText type="password" labelSize={2} name="senha" label="Senha"/>

                    <Button variant="primary" type="submit"><LogIn /> Entrar</Button>
                </Form>
            </FormProvider>
        </section>
    </div>;
}

export default LoginPage;