import React, {useRef, useState} from 'react'

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import '../../components/Core/AbstractGrid.scss';
import {useForm, FormProvider} from "react-hook-form";
import UiInputText from "../../components/Form/UiInputText";
import Form from "../../components/Form/Form";

import Overlay from "../../components/Overlay";
import {Plus, Search} from "react-feather";
import UiInputList from "../../components/Form/UiInputList";
import {post} from "../../services/ApiService";
import UIInputFile from "../../components/Form/UIInputFile";
import {Toast} from "primereact/toast";
import {menus} from "../../components/Layout";
import {Util} from "../../util/Util";
const HtmlTableToJson = require('html-table-to-json');

const strToDecimal = (str: string) => {
    return parseInt(str.replace(/([,\.])/g, '')) / 100;
}

export default function Importar(props: any) {
    //const navigate = useNavigate();
    const openMenu = props.openMenu;
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<any>();
    const [log, setLog] = useState<any>();
    const [message, setMessage] = useState(null);
    const methods = useForm({
        defaultValues: props.data
    });
    const { handleSubmit, watch, setValue } = methods;
    const toast = useRef(null);

    const actionList = [
        { id: 1, title: 'Importar Fatura em PDF', action: 'convertFaturaToJson' },
    ];

    const allData = watch();

    async function importar(){
        setLoading(true);
        post(`/?route=neto/form&action=uploadFromJson`, {
            fatura: JSON.stringify(data)
        })
            .then((response: any) => {
                console.log(response);
                setLoading(false);
                toastAlert({
                    severity: 'success',
                    summary: 'Sucesso!',
                    detail: response.data.message ?? response.data,
                    life: 15000
                });
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
                toastAlert({
                    severity: 'error',
                    summary: 'Erro!',
                    detail: err,
                    life: 3000
                });
            });

    }

    const doActionUpload = () => {
        const doRequest = (action: string) => {
            setLoading(true);
            let data = allData;
            delete data.action;
            post(`/?route=neto/form&action=${action}`, data)
                .then((response: any) => {
                    setLoading(false);
                    if (response.status != 200) {
                        return Promise.reject('Falha ao carregar');
                    }
                    if (response.data.success != true) {
                        return Promise.reject(response.data.message);
                    }

                    setData(response.data.payload);
                    //setValue('texto', response.data.payload);
                    toastAlert({
                        severity: 'success',
                        summary: 'Sucesso!',
                        detail: response.data.message,
                        life: 3000
                    });
                })
                .catch((err) => {
                    console.log(err);
                    setLoading(false);
                    toastAlert({
                        severity: 'error',
                        summary: 'Erro!',
                        detail: err,
                        life: 3000
                    });
                })
        }

        const action = actionList.find((item) => item.id === parseInt(allData.action));
        if(action){
            setLoading(true);
            doRequest(action.action);
        }else{
            alert('Escolha uma ação');
        }
    }

    const toastAlert = (payload: any) => {
        // @ts-ignore
        toast.current.show(payload);
    }

    const criarKit = () => {
        if(!data){
            toastAlert({
                severity: 'error',
                summary: 'Erro!',
                detail: 'Não há dados para criar o kit',
                life: 3000
            });
            return;
        }

        let menu = menus.find(item => {
            return item.name == "Kits"
        });

        if(menu) {
            menu.props = {
                ...menu.props,
                ...{
                    onInitOpenData: {
                        ...data
                    }
                }
            }

            openMenu({
                ...menu
            });
        }
        /*navigate('/kit', {
            state: {
                onInitOpenData: {
                   ...data
                }
            }
        });*/
    }

    return (
        <div className="simple-page">
            <Toast ref={toast} />
            <Overlay show={loading} />

            <FormProvider {...methods} >
                <Form onSubmit={handleSubmit(props.triggerSubmit)} methods={methods}>
                    <div className="row" style={{ height: 'calc(100vh - 115px)', maxWidth: 'calc(100vw - 20px)' }}>
                        <div className="col-sm-3">

                            <UIInputFile size={12}  name="pdf" label="Arquivo (pdf/xlsx)"/>
                            <UiInputList size={12} name="action" label="Ação" list={actionList} idField="id" titleField="title" />
                            { (allData.action > 1 && allData.action < 5) && <UiInputText size={12}  name="percentualDesconto" label="Desconto"/> }
                            { (allData.action == 5) && <UiInputText size={12}  name="minPlacas" label="Minimo de Placas"/> }
                            { (allData.action == 5) && <UiInputText size={12}  name="maxPlacas" label="Máximo de Placas"/> }
                            <button onClick={doActionUpload} className="btn btn-sm btn-success"><Search /> Enviar PDF</button>

                        </div>
                        <div className="col-sm-9">
                            { data && <button onClick={importar} className="btn btn-sm btn-success"><Plus /> Importar</button> }
                            { data && (<pre>{JSON.stringify(data, null, 2)}</pre>) }
                            { message && (<div className="alert alert-info">{message}</div>) }
                            { log && (<pre>{log}</pre>) }
                        </div>
                    </div>
                </Form>
            </FormProvider>


        </div>
    );

}