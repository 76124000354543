import React, {useEffect} from 'react'
import UiInputText from "../components/Form/UiInputText";
import Form from "../components/Form/Form";
import {useForm, FormProvider, UseFormReturn, FieldValues, UseFieldArrayReturn} from "react-hook-form";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import UiInputSuggest from "../components/Form/UiInputSuggest";
import UiGridForm from "../components/Form/UiGridForm";
import UiInputList from "../components/Form/UiInputList";
import UiInputNumber from "../components/Form/UiInputNumber";
import moment from "moment";
import {getExtruturaList} from "../services/ApiService";
import UiInputCheckbox from "../components/Form/UiInputCheckbox";
import {Tab, Tabs} from "react-bootstrap";

export default function KitForm(props: any){

    let defaultValues = props.data;
    if(!defaultValues){
        defaultValues = {
            //dataFechamento: moment().format('YYYY-MM-DD')
        }
    }

    const methods = useForm({
        defaultValues: defaultValues
    });
    const { register, handleSubmit, watch, setValue } = methods;

    const tipoList = [
        { id: 1, title: 'Ongrid' },
        { id: 2, title: 'Offgrid' },
        { id: 3, title: 'Hibrido' },
    ];

    const fornecedorList = [
        { id: 1, title: 'Atinos' },
        { id: 2, title: 'Aldo' },
        { id: 3, title: 'BelEnergy' },
    ];

    const ligacaoList = [
        { id: 1, title: 'Monofasico' },
        { id: 2, title: 'Bifasico' },
        { id: 3, title: 'Trifasico' },
    ];
    const onSelectOrcamento = (data: any) => {
        if(data){
            if(!allData.precoVenda || allData.precoVenda == '' || allData.precoVenda < 1){
                setValue('precoVenda', data.kit_preco_venda);
                setValue('precoEquipamentos', data.kit_preco);
                setValue('precoMaoObra', data.kit_preco_venda - data.kit_preco);
                setValue('geracaoGarantida', data.geracao_garantida);
            }
        }
    }

    const baseEstruturaList: any = getExtruturaList();
    const estruturaList = Object.keys(baseEstruturaList).map((key) => {
        return { id: key, title: baseEstruturaList[key] };
    })

    const allData = watch();

    return (
        <Modal centered size="xl" show={true} animation={false} onHide={props.triggerClose}>
            <FormProvider {...methods} >
                <Form onSubmit={handleSubmit(props.triggerSubmit)} methods={methods}>
                    <Modal.Body>
                        <div className="row">
                            <UiGridForm name="produtoList" label="Produtos">
                                <UiInputText size={2} name="quantidade" label="Quantidade" />
                                <UiInputText size={9} name="produto.nome" label="Nome" />
                                <UiInputText size={1} name="produto.ordem" label="Ordem" />
                            </UiGridForm>

                            <UiInputList size={3} name="tipo" list={tipoList} idField="id" titleField="title" label="Tipo" />
                            <UiInputList size={3} name="fornecedor" list={fornecedorList} idField="id" titleField="title" label="Fornecedor" />
                            <UiInputText size={6} labelSize={2} register={register} name="referencia" label="Referencia"/>
                            <UiInputList size={3} name="estrutura" list={estruturaList} idField="id" titleField="title" label="Estrutura" />

                            <UiInputNumber mode="decimal" size={3} register={register} name="numeroPlacas" label="Quantidade de Placas"/>
                            <UiInputNumber mode="decimal" size={3} register={register} name="potenciaPlacas" label="Potencia das PLacas"/>
                            <UiInputNumber mode="decimal" size={3} register={register} name="inversor" label="Inversor"/>
                            <UiInputList size={3} name="ligacao" list={ligacaoList} idField="id" titleField="title" label="Ligação" />

                            <UiInputNumber mode="decimal" size={3} register={register} name="kwp" label="kWp" minFractionDigits={2} />
                            <UiInputNumber size={2} register={register} name="preco" label="Preço"/>
                            <UiInputNumber mode="decimal" size={2} register={register} name="peso" label="Peso"/>
                            <UiInputNumber mode="decimal" size={2} register={register} name="area" label="Área"/>

                            <UiInputNumber mode="decimal" size={2} register={register} name="mppt" label="MPPTs"/>
                            <UiInputNumber mode="decimal" size={2} register={register} name="voltagem" label="Voltagem"/>

                            <UiInputText size={3} register={register} name="imagem" label="Imagem"/>
                            <UiInputText size={3} register={register} name="link" label="Link"/>
                            <UiInputCheckbox size={2} register={register} name="disponivel" label="Disponível"/>

                            {/*<pre>{JSON.stringify(allData, null, 2)}</pre>*/}
                        </div>

                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="secondary" onClick={props.triggerClose}>Fechar</Button>
                        <Button variant="primary" type="submit">Salvar</Button>
                        <Button variant="primary" onClick={() => { props.triggerSubmitAsNew(allData) }}>Salvar como novo</Button>
                    </Modal.Footer>
                </Form>
            </FormProvider>
        </Modal>
    )
}