import React, {useRef, useState} from 'react'

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import '../components/Core/AbstractGrid.scss';
import classNames from "classnames";

import {Box, FileText, Home as HomeIcon, List, Menu, Tablet, User, Users, X, Zap} from 'react-feather';
import Importar from "./Neto/Importar";
import NewAbstractGrid from "../components/Core/NewAbstractGrid";
import MovimentacaoForm from "./MovimentacaoForm";
import NetoHome from "./NetoHome";
import TagForm from "./TagForm";

const tabs = [
    {
        name: 'Dashboard',
        icon: <Box/>,
        uuid: 'home',
        component: NetoHome,
        props: {}
    },
    {
        name: 'Movimentações',
        icon: <Box/>,
        uuid: 'movimentacoes',
        component: React.memo(NewAbstractGrid),
        props: {router: 'movimentacao', formComponent: MovimentacaoForm}
    },
    {
        name: 'Tags',
        icon: <Box/>,
        uuid: 'tags',
        component: React.memo(NewAbstractGrid),
        props: {router: 'tag', formComponent: TagForm}
    },
    {
        name: 'Importar',
        icon: <Box/>,
        uuid: 'importar',
        component: Importar,
        props: {}
    }
]

export default function Neto(props) {
    const [activeTab, setActiveTab] = useState(tabs[0].uuid);

    return <div style={{margin: '10px', border: '1px solid #CCC', height: '100%', display: 'flex', flexDirection: 'column'}}>
        <nav className="nav nav-tabs">
            {tabs && tabs.map((tab) => {
                return (
                    <div key={tab.uuid}
                         onClick={(event) => {
                             setActiveTab && setActiveTab(tab.uuid);
                         }} className={classNames('nav-link', {
                        'active': activeTab == tab.uuid
                    })}>
                        {tab.name}
                    </div>
                )
            })}
        </nav>
        <div className="tab-content" style={{grow: '1'}}>
            {tabs && tabs.map((tab) => {
                const TabContentComponent = tab.component;
                return (
                    <div key={'tab-content-' + tab.uuid}
                         className={classNames('tab-pane', {
                            'show active': activeTab == tab.uuid
                         })}
                         id={'tab-' + tab.uuid}
                    >
                        <TabContentComponent {...tab.props} />
                    </div>
                )
            })}
        </div>
    </div>
}