import React from 'react';
import {formatter} from "./DashboardUtil";

const TableChart = ({ group }) => {
    return <div className={"table-chart"}>
        <table>
            <thead>
            <tr>
                {
                    group.data.columns.map((columnValue, rowIdx) => {
                        let className = null;
                        if(group.align && group.align[rowIdx]){
                            className = 'align-'+group.align[rowIdx];
                        }
                        return <th className={className}>{columnValue}</th>
                    })
                }
            </tr>
            </thead>
            <tbody>
            {
                group.data.rows.map(row => {
                    return <tr>
                        {
                            row.map((rowValue, rowIdx) => {
                                let className = null;
                                if(group.formatter && group.formatter[rowIdx]){
                                    rowValue = formatter(rowValue, group.formatter[rowIdx]);
                                }
                                if(group.align && group.align[rowIdx]){
                                    className = 'align-'+group.align[rowIdx];
                                }
                                return <td className={className}>{rowValue}</td>
                            })
                        }
                    </tr>
                })
            }
            </tbody>
        </table>
    </div>
}

export default TableChart;