import React, {ReactElement, useContext} from 'react'
import {Plus, Trash2} from "react-feather";
import {FormContext} from "../../pages/OrcamentoForm";
import {useFieldArray, useFormContext} from "react-hook-form";
import {Draggable} from "react-drag-reorder";

const recursiveMap = (
    children: ReactElement[],
    fn: (child: ReactElement, childIndex: number) => ReactElement
): ReactElement[] => {
    return React.Children.map(children, (child, childIndex) => {
        if (!React.isValidElement(child)) {
            return child;
        }

        if ((child as ReactElement).props.children) {
            const props = {
                children: recursiveMap((child as ReactElement).props.children, fn)
            }
            child = React.cloneElement(child, props);
        }

        return fn(child, childIndex);
    });
}

export default function UiGridForm({children, label, name, size, idField, titleField, router, extraElements, ...rest}: any) {
    const formContext = useFormContext();
    const { control, setValue, watch } = formContext;
    const fieldsArray = useFieldArray({
        control,
        name: name,
    });
    const { fields, append, prepend, remove, swap, move, insert } = fieldsArray;

    const allData = watch();
    const add = () => {
        append({ });
        //setValue(name, oldValues: any => [ ...oldValues, {} ]);
    }

    return (
        <div className={`col-sm-${(size) ? size : 12}`}>
            <div className="form-item">
                <label className="form-label">{label}</label>

                <div className={`field-container`}>
                    {
                        fields.map((field: any, fieldIndex: any) => {
                            return <div className="ui-grid-form-container" key={"item-"+fieldIndex}>
                                <div className="row">
                                        {
                                            React.Children.map(children, (child, childIndex) => {
                                                let newProps = {
                                                    ...child.props,
                                                    key: name + '_' + fieldIndex + '_' + childIndex
                                                };
                                                if(child.props.name){
                                                    newProps = {
                                                        ...newProps,
                                                        name: `${name}.${fieldIndex}.${child.props.name}`
                                                    }
                                                }
                                                return React.cloneElement(child, newProps)
                                            })
                                        }
                                </div>
                                <button type="button" className="btn-close btn btn-danger btn-xs" onClick={() => remove(fieldIndex)}><Trash2 strokeWidth={2}  width={22} /></button>
                                { extraElements && extraElements(`${name}.${fieldIndex}`, formContext, fieldIndex, fieldsArray) }
                            </div>
                        })
                    }
                    <button type="button" className="btn btn-success btn-xs" onClick={add}><Plus strokeWidth={2} width={22}/></button>
                </div>

            </div>
        </div>
    )
}