import React, {useEffect, useState} from 'react'
import UiInputText from "../components/Form/UiInputText";
import Form from "../components/Form/Form";
import {useForm, FormProvider} from "react-hook-form";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import UiInputSuggest from "../components/Form/UiInputSuggest";
import UiGridForm from "../components/Form/UiGridForm";
import UiInputList from "../components/Form/UiInputList";
import UiInputDate from "../components/Form/UiInputDate";
import UiInputNumber from "../components/Form/UiInputNumber";
import moment from "moment";
import UiInputTextEditor from "../components/Form/UiInputTextEditor";
import UiInputTextarea from "../components/Form/UiInputTextarea";
import {get} from "../services/ApiService";
import UiInputNumberPercentage from "../components/Form/UiInputNumberPercentage";
import UiGridFormTable from "../components/Form/UiGridFormTable";
import {Util} from "../util/Util";
import UiInputCheckbox from "../components/Form/UiInputCheckbox";
import UiInputNumberInteger from "../components/Form/UiInputNumberInteger";

const doFetchCusto = (contratoId, custoList, setValue) => {
    get(`/?route=contrato/form&action=getCustos&rows=${JSON.stringify([{
        id: contratoId,
        custoList: custoList
    }])}`)
        .then((response) => {
            if (response.status == 200) {
                const data = response.data;
                setValue(data);
            } else {
                alert('Falha ao carregar o consumo');
            }
        })
        .catch((err) => {
            alert('Falha ao carregar o consumo: ' + err);
        })
}

const CustosLinkComponent = (props) => {
    const { watch, getValues, setValue } = props.formContext;
    const [ isLoading, setIsLoading ] = useState(false);

    const allData = watch();
    let contratoId = allData?.id;

    const base = props.name; //.split('.').slice(0, -1).join('.');
    const consumoData = getValues(base);

    return <>
        {
            <button type="button" onClick={() => {
                if(window.confirm("Isso vai sobrescrever todos os custos da tabela, continuar?")) {
                    setIsLoading(true);
                    doFetchCusto(contratoId, consumoData, (res) => {
                        setIsLoading(false);
                        setValue('calculoCusto', res?.payload);
                        setValue(base, res?.payload?.custos);
                    })
                }
            }} className="btn btn-primary btn-xs">{ isLoading ? `Carregando...` : `Montar baseado no orçamento` }</button>
        }
    </>
}


export default function ContratoForm(props){

    let defaultValues = props.data;
    if(!defaultValues){
        defaultValues = {
            dataFechamento: moment().format('YYYY-MM-DD')
        }
    }

    const methods = useForm({
        defaultValues: defaultValues
    });
    const { handleSubmit, watch, setValue } = methods;

    const situacaoList = [
        { id: 1, title: 'Aguardando Pagamento' },
        { id: 2, title: 'Aguardando Equipamentos' },
        { id: 3, title: 'Aguardando Instalação' },
        { id: 4, title: 'Aguardando Homologação' },
        { id: 5, title: 'Homologado' },
        { id: 6, title: 'Em prospecção' },
        { id: 7, title: 'Cancelado' },
    ];

    const modeloList = [
        { id: 1, title: 'Argon Recebe Tudo - Pix' },
        { id: 2, title: 'Argon Mão de Obra e Fornecedor Equipamentos' },
        { id: 3, title: 'Argon Recebe Tudo - Cartão de Crédito' },
        { id: 4, title: 'Meu Financiamento Solar - Nota Fiscal Antecipada' },
        { id: 5, title: '50% a vista + 50% em 30 dias' },
        { id: 6, title: 'Ex Tarifário' },
        { id: 7, title: 'Cartão de Crédito + Dinheiro na Instalação' },
        { id: 8, title: '50% de Entrada e 50% na Instalação' },
        { id: 9, title: 'Modelo 9' },
        { id: 10, title: 'Modelo 10' },
        { id: 9999, title: 'Personalizado manualmente' },
    ];

    const tipoFioList = [
        { id: 1, title: 'Aluminio' },
        { id: 2, title: 'Cobre' },
    ];

    const allData = watch();
    const onSelectOrcamento = (data) => {
        if(data){
            if(!allData.precoVenda || allData.precoVenda == '' || allData.precoVenda < 1){
                setValue('precoVenda', data.kit_preco_venda);
                setValue('precoEquipamentos', data.kit_preco);
                setValue('precoMaoObra', data.kit_preco_venda - data.kit_preco);
                setValue('geracaoGarantida', data.geracao_garantida);
            }
        }
    }

    const calcularCusto = () => {
        let custoList = allData.custoList;
        let precoVenda = allData.precoVenda;
        let maoDeObra = allData.precoMaoObra;
        const custoTotal = custoList.reduce((n, { value }) => n + value, 0);

        const sobraEmpresa = (precoVenda - custoTotal);
        const lucroLiquido = ((sobraEmpresa / precoVenda)*100);

        setValue('calculoCusto', {
            valorBase: precoVenda,
            maoDeObra: maoDeObra,
            lucroLiquido: lucroLiquido.toFixed(2) * 1,
            custoTotal: custoTotal.toFixed(2) * 1,
            sobraEmpresa: sobraEmpresa.toFixed(2) * 1
        });
    }

    useEffect(() => {

        if(allData.precoVenda && allData.precoEquipamentos){
            setValue('precoMaoObra', allData.precoVenda - allData.precoEquipamentos);
        }

    }, [ allData.precoEquipamentos ])

    const custoColor = (allData?.calculoCusto?.lucroLiquido < 10)?(allData?.calculoCusto?.lucroLiquido<5)?'#C00':'#009':'#090'

    return (
        <Modal centered size="xl" show={true} animation={false} onHide={props.triggerClose}>
            <FormProvider {...methods} >
                <Form onSubmit={handleSubmit(props.triggerSubmit)} methods={methods}>
                    <Modal.Body>
                        <div className="row">
                            <UiInputSuggest onSelect={onSelectOrcamento} size={3} router="orcamento" idField="id" titleField="cliente.nome" name="orcamento" label="Orçamento"/>
                            <UiInputDate dateFormat="dd/mm/yy" size={3} name="dataFechamento" label="Data Fechamento"/>
                            {/*<UiInputNumber mode="decimal" size={3} name="geracaoGarantida" label="Geração Garantida"/>*/}
                            <UiInputList size={3} name="situacao" list={situacaoList} idField="id" titleField="title" label="Situacao" />
                            <UiInputSuggest size={3} router="cliente" idField="id" titleField="nome" name="clienteInstalacao" label="Local de Instalação"/>
                        </div>

                        <div className="row">
                            <div className={`col-sm-3`}>
                                <div className="row">
                                    <UiInputNumber size={12} name="precoVenda" label="R$ Venda"/>
                                    <UiInputNumber size={12} name="precoEquipamentos" label="R$ Equipamentos"/>
                                    <UiInputNumber size={12} name="precoMaoObra" label="R$ Mão de Obra"/>
                                </div>

                                { allData?.calculoCusto?.sobraEmpresa && <div className="margemLucro">
                                    Margem: <b>{Util.moeda(allData.calculoCusto.sobraEmpresa)}</b> - <b style={{color: custoColor}}>{allData.calculoCusto.lucroLiquido}%</b>
                                </div> }
                                <button type="button" onClick={calcularCusto} className="btn btn-primary btn-xs mt-2">Recalcular</button>

                            </div>
                            <div className={`col-sm-9`}>

                                <UiGridFormTable name="custoList" label="Custos" linkComponent={CustosLinkComponent}>
                                    <UiInputText asTable={true} size={8} name="title" label="Descrição"/>
                                    <UiInputNumber asTable={true} size={2} name="value" label="Valor"/>
                                    <UiInputNumberPercentage asTable={true} size={2} minFractionDigits={2} maxFractionDigits={2} name="percent" label="%" inputClassName="text-right"/>
                                    {/*<UiInputCheckbox asTable={true} name="pago" size={1} />*/}
                                </UiGridFormTable>


                            </div>
                        </div>

                        <hr />

                        <div className="row">
                            <UiInputList size={3} name="modelo" list={modeloList} idField="id" titleField="title" label="Modelo do Contrato" />
                            {
                                allData.modelo == 9999 &&
                                <>
                                    <UiInputTextEditor size={9}  name="descPagamento" label="Texto" style={{ height: 300, overflow: 'auto' }}/>
                                </>
                            }
                        </div>

                        <hr />

                        <div className="row">
                            <UiInputTextEditor size={12} name="dados.textoLivre" label="Dados Extras" style={{ height: 300, overflow: 'auto' }}/>
                        </div>

                        <hr />

                        <div className="row">
                            <UiInputNumberInteger size={3} name="dados.calculoPerda.distancia" label="Distancia"/>
                            <UiInputNumberInteger size={3} name="dados.calculoPerda.bitola" label="Bitola do Fio"/>
                            <UiInputNumberInteger size={3} name="dados.calculoPerda.corrente" label="Corrente"/>
                            <UiInputList size={3} name="dados.calculoPerda.tipoFio" list={tipoFioList} idField="id" titleField="title" label="Situacao" />
                        </div>

                        <pre>{ JSON.stringify(allData.dados) }</pre>

                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="secondary" onClick={props.triggerClose}>Fechar</Button>
                        <Button variant="primary" type="submit">Salvar</Button>
                    </Modal.Footer>
                </Form>
            </FormProvider>
        </Modal>
    )
}