import React, {useContext} from 'react'
import {useFormContext} from "react-hook-form";

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export default function UiInputTextEditor({ label, name, size, linkComponent, ...rest }){
    const formContext = useFormContext();
    const { register, setValue, watch } = formContext;
    const LinkComponent = linkComponent;
    let value = watch(name);

    return (
        <div className={`col-sm-${(size) ? size : 12} ui-input-text-editor`}>
            <div className="form-item">
                <label className="form-label" onClick={(event) => event.preventDefault()}>{label}{
                    LinkComponent &&
                    // @ts-ignore
                    <LinkComponent formContext={formContext} name={name}/>
                }</label>
                <div className="field-container">
                    <ReactQuill
                        theme="snow"
                        value={value}
                        onChange={(e) => { setValue(name, e) }} />
                </div>
            </div>
        </div>
    )
}