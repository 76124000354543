import React, { useContext } from 'react';
import {Util} from "../util/Util";
import {getExtruturaList} from "../services/ApiService";
import {useFormContext} from "react-hook-form";
import AuthContext, {AuthProvider} from "../services/AuthProvider";
import UiInputNumber from "../components/Form/UiInputNumber";
import ReactSlider from 'react-slider'

export function getCustoDetalhado(precoVenda: any, descontoVendedor: any, precoCusto: any, numeroPlacas: any, potenciaPlacas: any, inversor: any, data: any, acesso: any/*, engenheiroTabelaList: any*/){
    const kwp = (numeroPlacas * potenciaPlacas) / 1000;
    const valorBase = precoVenda;
    const comissaoVendedor = 0.08;
    const notaFiscal = 0.12;
    const comissaoNeto = 0.02;

    const maoDeObra = precoVenda - precoCusto;

    const instalacao = 0.05;
    //let valorInstalacao = valorBase * instalacao; // Jeito antigo, 5% da venda.
    let valorInstalacao = (numeroPlacas * 80);
    if(valorInstalacao < 1300){
        //valorInstalacao = 1300;
    }

    //valorInstalacao = valorInstalacao * 1.25; // adicional de 5% referente insumos
    const percentualInstalacao = (valorInstalacao / valorBase);

    let valorEngenheiro = 0;

    /*if(!data.engenheiro){
        data.engenheiro = '3';
    }

    if(data.engenheiro) {
        const engenheiroList = engenheiroTabelaList[data.engenheiro].tabela;
        engenheiroList.map((item: any) => {
            if (kwp <= item.ate) {
                if (item.valorFixo > 0) {
                    valorEngenheiro = item.valorFixo;
                } else if (item.valorPercentual > 0) {
                    valorEngenheiro = item.valorPercentual * precoVenda;
                }
            }
        });
    }*/

    const percentualEngenheiro = (valorEngenheiro / valorBase);
    const valorART = 62.57;
    // const artList = [
    //     // { ate: 499999, valorFixo: 5 * 233.94, valorPercentual: 0 },
    //     // { ate: 399999, valorFixo: 4 * 233.94, valorPercentual: 0 },
    //     // { ate: 299999, valorFixo: 3 * 233.94, valorPercentual: 0 },
    //     // { ate: 199999, valorFixo: 2 * 233.94, valorPercentual: 0 },
    //     // { ate: 99999, valorFixo: 233.94, valorPercentual: 0 },
    //     // { ate: 15000, valorFixo: 88, valorPercentual: 0 }
    //     { ate: 0, valorFixo: 62.57, valorPercentual: 0 }
    // ];
    //
    // let valorART = 0;
    // artList.map((item: any) => {
    //     if(maoDeObra <= item.ate) {
    //         if (item.valorFixo > 0) {
    //             valorART = item.valorFixo;
    //         } else if (item.valorPercentual > 0) {
    //             valorART = item.valorPercentual * precoVenda;
    //         }
    //     }
    // });

    const percentualART = (valorART / valorBase);

    const valorNotaFiscal = (maoDeObra * notaFiscal);
    const percentualNotaFiscal = (valorNotaFiscal / valorBase);

    const valorInsumos = valorInstalacao * 0.25;
    const percentualInsumos= (valorInsumos / valorBase);

    const valorComissaoVendedor = (valorBase * comissaoVendedor) - descontoVendedor;
    const percentComissaoVendedor = (valorComissaoVendedor / valorBase);
    const percentDescontoVendedor = (descontoVendedor / valorBase);

    const custoList = [
        { show: true, title: 'Engenheiro', value: valorEngenheiro, percent: percentualEngenheiro },
        { show: true, title: 'ART', value: valorART, percent: percentualART },
        { show: true, title: 'Instalação', value: valorInstalacao, percent: percentualInstalacao },
        { show: true, title: 'Insumos de Instalação', value: valorInsumos, percent: percentualInsumos },
        { show: true, title: 'Nota Fiscal', value: maoDeObra * notaFiscal, percent: percentualNotaFiscal },
        { show: true, title: 'Comissão do Vendedor', value: valorComissaoVendedor, percent: percentComissaoVendedor },
        { show: true, title: 'Desconto do Vendedor', value: descontoVendedor, percent: percentDescontoVendedor },
        { show: true, title: 'Comissão do Neto', value: valorBase * comissaoNeto, percent: comissaoNeto },
        { show: true, title: 'Comissão do Jaime', value: valorBase * comissaoNeto, percent: comissaoNeto },
        { show: true, title: 'Comissão do Valdenir', value: valorBase * comissaoNeto, percent: comissaoNeto },
    ];

    const custoTotal = custoList.reduce((n, { value }) => n + value, 0) + (precoCusto * 1);

    const lucroLiquido = (((precoVenda - custoTotal) / precoVenda)*100);

    let lucroMinimo = precoVenda * 0.10;
    if(lucroMinimo <= 1500){
        lucroMinimo = 1500;
    }
    let precoMinimo = custoTotal + lucroMinimo;
    let milhares = Math.round(precoMinimo / 1000) * 1000;
    let centenas = precoMinimo - milhares;
    /*if(centenas > 600){
        centenas = 900;
    }else if(centenas > 400 && centenas < 600){
        centenas = 500;
    }else{
        centenas = 900;
        milhares = milhares - 1000;
    }*/
    centenas = Math.round(centenas / 100) * 100;
    precoMinimo = milhares + centenas;

    let margemSegura = 0;
    if(acesso !== 1){
        margemSegura = 6;
    }

    return {
        custos: custoList,
        custoTotal: custoTotal,
        maoDeObra: maoDeObra,
        precoVenda: Math.round(precoMinimo),//Math.round((custoTotal + (  ))),
        lucroLiquido: lucroLiquido - margemSegura,
        lucroLiquidoReal: lucroLiquido
    }
}

export function KitDetail({ kit, precoVenda, descontoVendedor, consumoMedio, data }: any) {
    if(!kit.numeroPlacas){
        kit.referencia = kit.kit_referencia;
        kit.numeroPlacas = kit.kit_numero_placas;
        kit.inversor = kit.kit_inversor;
        kit.estrutura = kit.kit_estrutura;
        kit.link = kit.kit_link;
        kit.imagem = kit.kit_imagem;
        kit.potenciaPlacas = kit.kit_potencia_placas;
        kit.preco = kit.kit_preco;
        kit.kwp = kit.kit_kwp;
        kit.geracaoGarantida = kit.geracao_garantida;
    }else{
        let incidenciaSolar = 3.36;
        if(data.incidenciaSolar && data.incidenciaSolar != ''){
            incidenciaSolar = data.incidenciaSolar;
        }
        console.log(incidenciaSolar);
        kit.geracaoGarantida = kit.numeroPlacas * Math.round((kit.potenciaPlacas * incidenciaSolar * 30) / 1000)
    }

    if(!Array.isArray(kit.product_list)){
        kit.product_list = JSON.parse(kit.product_list);
    }

    /*const engenheiroTabelaList = [
        {
            id: 1,
            name: 'Jefferson Tironi (Concórdia)',
            tabela: [
                {ate: 99999, valorFixo: 0, valorPercentual: 0.016},
                {ate: 19, valorFixo: 1100, valorPercentual: 0},
                {ate: 14, valorFixo: 1000, valorPercentual: 0},
                {ate: 8, valorFixo: 900, valorPercentual: 0},
                {ate: 5, valorFixo: 800, valorPercentual: 0},
                {ate: 4, valorFixo: 700, valorPercentual: 0},
                {ate: 3, valorFixo: 600, valorPercentual: 0}
            ]
        },
        {
            id: 2,
            name: 'Elton Rodrigo Ortunio (Blumenau)',
            tabela: [
                {ate: 99999, valorFixo: 0, valorPercentual: 0.02},
                {ate: 55, valorFixo: 1400, valorPercentual: 0},
                {ate: 45, valorFixo: 1300, valorPercentual: 0},
                {ate: 35, valorFixo: 1200, valorPercentual: 0},
                {ate: 25, valorFixo: 1100, valorPercentual: 0},
                {ate: 15, valorFixo: 800, valorPercentual: 0},
                {ate: 10, valorFixo: 600, valorPercentual: 0},
                {ate: 7, valorFixo: 500, valorPercentual: 0},
                {ate: 5, valorFixo: 400, valorPercentual: 0},
            ]
        },
        {
            id: 3,
            name: 'Jaime da Silva',
            tabela: [
                {ate: 99999, valorFixo: 0, valorPercentual: 0},
                /*{ate: 99999, valorFixo: 0, valorPercentual: 0.02},
                {ate: 55, valorFixo: 1400, valorPercentual: 0},
                {ate: 45, valorFixo: 1300, valorPercentual: 0},
                {ate: 35, valorFixo: 1200, valorPercentual: 0},
                {ate: 25, valorFixo: 1100, valorPercentual: 0},
                {ate: 15, valorFixo: 800, valorPercentual: 0},
                {ate: 10, valorFixo: 600, valorPercentual: 0},
                {ate: 7, valorFixo: 500, valorPercentual: 0},
                {ate: 5, valorFixo: 400, valorPercentual: 0},
            ]
        },
        {
            id: 4,
            name: 'Natan Ruan Neckel (Rio do Sul)',
            tabela: [
                {ate: 99999, valorFixo: 0, valorPercentual: 0.02},
                {ate: 75, valorFixo: 2600, valorPercentual: 0},
                {ate: 60, valorFixo: 2200, valorPercentual: 0},
                {ate: 40, valorFixo: 1800, valorPercentual: 0},
                {ate: 30, valorFixo: 1500, valorPercentual: 0},
                {ate: 20, valorFixo: 1250, valorPercentual: 0},
                {ate: 15, valorFixo: 1000, valorPercentual: 0},
                {ate: 10, valorFixo: 800, valorPercentual: 0},
                {ate: 5, valorFixo: 590, valorPercentual: 0},
            ]
        }
    ];*/
    const { user } = useContext<any>(AuthContext);

    const { register, watch, setValue } = useFormContext();
    const custoDetalhado = getCustoDetalhado(precoVenda, descontoVendedor, kit.preco, kit.numeroPlacas, kit.potenciaPlacas, kit.inversor, data, user.acesso);
    const estruturaList: any = getExtruturaList();
    const folgaPlacas = Math.floor((kit.inversor * 1.3 * 1000) / kit.potenciaPlacas) - kit.numeroPlacas;

    // <pre>{ JSON.stringify(kit, null, 2) }</pre>

    const setMinimalValue = () => {
        setValue('precoVendaBase', custoDetalhado.precoVenda);
    }

    const custoColor = (custoDetalhado.lucroLiquido < 10)?(custoDetalhado.lucroLiquido<5)?'#C00':'#009':'#090'
    const custoColorReal = (custoDetalhado.lucroLiquidoReal < 10)?(custoDetalhado.lucroLiquidoReal<5)?'#C00':'#009':'#090'


    const showCosts = watch('showCosts');

    const precoMinimo = custoDetalhado.precoVenda;
    const precoMaximo = kit.preco * 3;

    /*useEffect(() => {
        custoDetalhado.custos.map((item: any, index: number) => {
            register(`custoDetalhado.${index}`);
        });
    }, [register]);*/

    return <>
        <div className="kit-detail">
        <div className="kit-detail-image">

            <a target="_blank" href={kit.link}><img src={kit.imagem} alt={kit.nome} /></a>
            <div className="kit-title">
                <table>
                    <tr>
                        <th>Qtd</th>
                        <th>Produto</th>
                    </tr>
                    { kit.product_list.map((product: any) => {
                        return <tr>
                            <td style={{textAlign: 'center'}}>{product.qtd}</td>
                            <td>{product.nome}</td>
                        </tr>
                    }) }
                </table>

                <br />
                {kit.referencia}<br />
                <b>{kit.kwp} kWp</b><br />
                {kit.numeroPlacas} x {kit.potenciaPlacas}W - Inversor: {kit.inversor} kW<br />
                {estruturaList[kit.estrutura] ?? kit.estrutura}<br />
                Folga para <b>{folgaPlacas}</b> placas<br />


                {/*{ user.acesso == 1 && <label className="simple-checkbox">*/}
                {/*    <input type="checkbox" { ...register('showCosts') } />*/}
                {/*    <span>Mostrar custos</span>*/}
                {/*</label> }*/}
                {/*Engenheiro:*/}
                {/*<select className="form-control" {...register('engenheiro')}>*/}
                {/*    { engenheiroTabelaList.map((item: any, index: any) => <option key={index} value={index}>{item.name}</option> )}*/}
                {/*</select>*/}
            </div>
        </div>
        <div className="kit-detail-text">

            <div className="row">
                <UiInputNumber size={4} step={100} readOnly={user.acesso != 1} name="precoVendaBase" label="Preço base"/>
                <UiInputNumber size={4} step={100} name="descontoVendedor" label="Desconto do Vendedor"/>
                <UiInputNumber size={4} step={100} readOnly={true} name="precoVenda" label="Preço de Venda"/>
                <div className="col-sm-12">
                    <div className="slider-container">
                        <ReactSlider
                            className="horizontal-slider"
                            thumbClassName="slider-thumb"
                            trackClassName="slider-track"
                            min={precoMinimo}
                            max={precoMaximo}
                            step={100}
                            onAfterChange={(value, index) => {
                                setValue('precoVendaBase', value);
                                //console.log(`onAfterChange: ${JSON.stringify({ value, index })}`)
                            }}
                            defaultValue={data.precoVendaBase}
                            renderThumb={(props, state) => <div {...props}>{Util.moeda(state.valueNow)}</div>}
                        />
                    </div>
                </div>
            </div>

            <table>
                <tbody>
                <tr>
                    <td style={{textAlign: 'right', width: '50%'}}>Geração estimada:</td>
                    <td style={{textAlign: 'right', width: '25%'}}><b>{kit.geracaoGarantida} kWh/mês</b></td>
                    <td style={{textAlign: 'center', width: '25%'}}>{(((kit.geracaoGarantida / consumoMedio))  * 100).toFixed(2)}%</td>
                </tr>
                <tr>
                    <td style={{textAlign: 'right', width: '50%'}}>Preço por kWh instalado:</td>
                    <td colSpan={2} style={{textAlign: 'center', width: '50'}}><b>{Util.moeda(precoVenda / kit.geracaoGarantida)}/kWh</b></td>
                </tr>
                <tr>
                    <td style={{textAlign: 'right'}}>Margem:</td>
                    <td colSpan={2} style={{textAlign: 'center', color: custoColor, fontSize: 14}}>{custoDetalhado.lucroLiquido.toFixed(2)}%</td>
                </tr>
                <tr>
                    <td style={{textAlign: 'right'}}>Preço do Kit:</td>
                    <td style={{textAlign: 'right'}}>{Util.moeda(kit.preco)}</td>
                    <td style={{textAlign: 'center'}}>{(((kit.preco / precoVenda))  * 100).toFixed(2)}%</td>
                </tr>
                { custoDetalhado.custos.map((item: any, index: number) => {
                    return item.show == true && <tr key={index}>
                        <td style={{textAlign: 'right'}}>{item.title}:</td>
                        <td style={{textAlign: 'right'}}>{Util.moeda(item.value)}</td>
                        <td style={{textAlign: 'center'}}>
                            {(item.percent * 100).toFixed(2)}%

                            {/*<InputNumber
                                onChange={(e) => setValue(`custoDetalhado.${index}`, e.value)}
                                value={watch(`custoDetalhado.${index}`)}

                                suffix=" %"
                                locale="pt-BR"
                            />*/}
                        </td>
                    </tr>
                }) }
                <tr>
                    <td style={{textAlign: 'right'}}>Custo total:</td>
                    <td style={{textAlign: 'right'}}>{Util.moeda(custoDetalhado.custoTotal)}</td>
                    <td style={{textAlign: 'center'}}>{((custoDetalhado.custoTotal / precoVenda)*100).toFixed(2)}%</td>
                </tr>
                <tr>
                    <td style={{textAlign: 'right'}}>Preço de Venda:</td>
                    <td style={{textAlign: 'right'}}>{Util.moeda(precoVenda)}</td>
                    <td style={{textAlign: 'center'}}>{((1 - (custoDetalhado.custoTotal / precoVenda))  * 100).toFixed(2)}%</td>
                </tr>
                {/*<tr style={{fontWeight: 'bold'}}>*/}
                {/*    <td style={{textAlign: 'right'}}>Lucro liquido:</td>*/}
                {/*    <td style={{textAlign: 'right'}}>{Util.moeda(precoVenda - custoDetalhado.custoTotal)}</td>*/}
                {/*    <td style={{textAlign: 'center', color: (custoDetalhado.lucroLiquido < 5)?'#C00':'#009'}}>{custoDetalhado.lucroLiquido.toFixed(2)}%</td>*/}
                {/*</tr>*/}
                <tr style={{fontWeight: 'bold'}}>
                    <td style={{textAlign: 'right'}}>Margem:</td>
                    <td style={{textAlign: 'right'}}>{Util.moeda(precoVenda - custoDetalhado.custoTotal)}</td>
                    <td colSpan={2} style={{textAlign: 'center', color: custoColorReal, fontSize: 14}} title={Util.moeda(precoVenda - custoDetalhado.custoTotal)}>{custoDetalhado.lucroLiquidoReal.toFixed(2)}%</td>
                </tr>
                <tr>
                    <td style={{textAlign: 'right'}}>Venda mínimo:</td>
                    <td style={{textAlign: 'center'}} colSpan={2} onClick={setMinimalValue}>{Util.moeda(custoDetalhado.precoVenda)}</td>
                </tr>
                </tbody>
            </table>
        </div>
    </div></>;
}