import React from 'react';
import Chart from "react-apexcharts";
import {formatter} from "./DashboardUtil";

const BarChart = ({ group }) => {

    const config = {
        series: group.series,
        options: {
            chart: {
                type: 'bar',
                height: 350
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '55%',
                    endingShape: 'rounded'
                },
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                show: true,
                width: 2,
                colors: ['transparent']
            },
            xaxis: {
                labels: {
                    show: false,
                    formatter: (val) => formatter(val, group.xFormatter)
                },
                categories: group.categories,
            },
            yaxis: {
                labels: {
                    show: false,
                    formatter: (val) => formatter(val, group.yFormatter)
                },
                title: {
                    //text: '$ (thousands)'
                }
            },
            fill: {
                opacity: 1
            },
            tooltip: {
                y: {
                    formatter: (val) => formatter(val, group.yFormatter)
                },
                x: {
                    formatter: (val) => formatter(val, group.xFormatter)
                }
            }
        },
    };

    return  <Chart
        options={config.options}
        series={config.series}
        type="bar"
        width="100%"
        height="100%"
    />
}

export default BarChart;