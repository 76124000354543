import React, {useContext, useEffect} from 'react'
import UiInputText from "../components/Form/UiInputText";
import Form from "../components/Form/Form";
import {useForm, FormProvider} from "react-hook-form";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import UiInputSuggest from "../components/Form/UiInputSuggest";
import UiInputList from "../components/Form/UiInputList";
import AuthProvider from "../services/AuthProvider";
import UiInputCheckbox from "../components/Form/UiInputCheckbox";

const acessoListBase: any = {
    1: 'Master',
    2: 'Vendedor',
    3: 'Supervisor',
};
const acessoList = Object.keys(acessoListBase).map((key) => {
    return { id: key, title: acessoListBase[key] };
})


export default function VendedorForm(props: any){
    const { user } = useContext<any>(AuthProvider);

    const parentProps = props.parentProps;
    const methods = useForm({
        defaultValues: props.data
    });
    const { register, handleSubmit, watch, setValue } = methods;
    const allData = watch();


    //const tabRef = parentProps.tabsRefs[parentProps.thisTabIndex];

    useEffect(() => {
        if(user.acesso == 3 && allData?.vendedor?.id != user?.id){
            setValue('supervisor', user);
        }
    }, []);

    return (
        <Modal centered size="sm" show={true} animation={false} onHide={props.triggerClose}>
            <FormProvider {...methods} >
                <Form onSubmit={handleSubmit(props.triggerSubmit)} methods={methods}>
                    <Modal.Body>
                        <div className="row">
                            <UiInputText size={12} name="nome" label="Nome completo"/>
                            <UiInputText size={12} name="email" label="E-mail"/>
                            <UiInputText size={12} name="telefone" label="Telefone"/>
                            <UiInputText size={12} name="cpf" label="CPF"/>
                            { user.acesso == 1 && <UiInputList size={12} name="acesso" list={acessoList} idField="id" titleField="title" label="Nível de Acesso" /> }
                            { user.acesso == 1 && <UiInputSuggest size={12} router="vendedor" idField="id" titleField="nome" name="supervisor" label="Supervisor"/> }

                            <UiInputCheckbox size={3} name="alterarSenha" label="Alterar senha?"/>
                            { allData.alterarSenha == true && <UiInputText size={9} name="senha" label="Nova senha"/> }
                        </div>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="secondary" onClick={props.triggerClose}>Fechar</Button>
                        <Button variant="primary" type="submit">Salvar</Button>
                    </Modal.Footer>
                </Form>
            </FormProvider>
        </Modal>
    )
}