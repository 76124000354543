import React, { useContext } from 'react';
import AuthProvider from "../services/AuthProvider";
import Layout from "./Layout";
import LoginPage from "./Login";

const Guard = () => {
    const { isLoggedIn } = useContext(AuthProvider);

    if(isLoggedIn){
        return <Layout />
    }

    return <LoginPage />
}
export default Guard;