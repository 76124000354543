import React from "react";
import {render} from "react-dom";

import {AuthProvider} from "./services/AuthProvider";
import Guard from "./components/Guard";

const rootElement = document.getElementById("root");
render(
    <AuthProvider>
        <Guard />
    </AuthProvider>,
    rootElement
);