import React from 'react'
import {useFormContext} from "react-hook-form";

export default function UIInputFile({ label, name, size, ...rest }: any){
    const formContext = useFormContext();
    const { register } = formContext;
    const LinkComponent = rest.linkComponent as JSX.Element;

    return (
        <div className={`col-sm-${(size) ? size : 12}`}>
            <div className="form-item">
                <label className="form-label">{label}{
                    LinkComponent &&
                    // @ts-ignore
                    <LinkComponent formContext={formContext} name={name}/>
                }</label>
                <div className="field-container">
                    <input type="file" className="form-control" {...register(name)} {...rest} />
                </div>
            </div>
        </div>
    )
}