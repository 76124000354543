export const getEnumColumnParams = enumMap => {
    let filterOptions = Object.keys(enumMap).map(key => {
        return {
            displayKey: key,
            displayName: enumMap[key],
            test: function(filterValue, cellValue) {
                return cellValue === key;
            },
            hideFilterInput: true
        };
    });

    filterOptions = filterOptions.sort((a,b) => (a.displayName > b.displayName) ? 1 : ((b.displayName > a.displayName) ? -1 : 0))

    return {
        cellRenderer: params => {
            if (!params.data) return "";
            const { value } = params;
            return enumMap[value];
        },
        filterParams: {
            buttons: ["reset", "apply"],
            closeOnApply: true,
            filterOptions: [
                "Nenhum",
                ...filterOptions
            ],
            suppressAndOrCondition: true
        }
    };
};
