import React, {Fragment, useEffect, useState } from 'react';
import {get} from "../../services/ApiService";

import { Move } from 'react-feather';

import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

import * as dayjs from 'dayjs'
import subDays from 'date-fns/subDays';
import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';
import addDays from 'date-fns/addDays';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import addMonths from 'date-fns/addMonths';

import {DateRangePicker} from "rsuite";

import Overlay from "../Overlay";

import { Responsive, WidthProvider } from "react-grid-layout";
import LineChart from "./LineChart";
import MetricChart from "./MetricChart";
import TimeSeriesChart from "./TimeSeriesChart";
import TableChart from "./TableChart";
import BarChart from "./BarChart";
const ResponsiveGridLayout = WidthProvider(Responsive);


function getFromLocalStorage(key, identifier) {
    let ls = {};
    if (global.localStorage) {
        try {
            ls = JSON.parse(global.localStorage.getItem(identifier)) || {};
        } catch (e) {
            /*Ignore*/
        }
    }
    return ls[key];
}

function saveToLocalStorage(key, value, identifier) {
    if (global.localStorage) {
        global.localStorage.setItem(identifier, JSON.stringify({
            [key]: value
        }));
    }
}

const defaultDate = [
    new Date(dayjs().format('YYYY') + '-01-01 00:00:00'),
    new Date(dayjs().format('YYYY') + '-12-31 23:59:59')
]

const predefinedBottomRanges = [
    /*{
        label: 'Hoje',
        value: [new Date(), new Date()]
    },
    {
        label: 'Ontem',
        value: [addDays(new Date(), -1), addDays(new Date(), -1)]
    },*/
    {
        label: 'Esta semana',
        value: [startOfWeek(new Date()), endOfWeek(new Date())]
    },
    {
        label: 'Últimos 7 dias',
        value: [subDays(new Date(), 6), new Date()]
    },
    {
        label: 'Últimos 30 dias',
        value: [subDays(new Date(), 30), new Date()]
    },
    {
        label: 'Últimos 60 dias',
        value: [subDays(new Date(), 60), new Date()]
    },
    {
        label: 'Últimos 90 dias',
        value: [subDays(new Date(), 90), new Date()]
    },
    {
        label: 'Últimos 120 dias',
        value: [subDays(new Date(), 120), new Date()]
    },
    {
        label: 'Este mês',
        value: [startOfMonth(new Date()), new Date()]
    },
    {
        label: 'Mês passado',
        value: [startOfMonth(addMonths(new Date(), -1)), endOfMonth(addMonths(new Date(), -1))]
    },
    {
        label: 'Este ano',
        value: [new Date(new Date().getFullYear(), 0, 1), new Date()]
    },
    {
        label: 'Ano passado',
        value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date(new Date().getFullYear(), 0, 0)]
    },
    {
        label: 'Todo o periodo',
        value: [new Date(new Date().getFullYear() - 5, 0, 1), new Date()]
    }
];

function Dashboard({ endpoint, identifier }){
    const recoveredLayouts = getFromLocalStorage("layouts", identifier) || {};

    const [isLoading, setIsLoading] = useState(true);
    const [layoutsOnState, setLayoutsOnState] = useState({});
    const [originalLayout, setOriginalLayout] = useState({});
    const [data, setData] = useState({});

    const [dateRange, setDateRange] = React.useState(defaultDate);

    const onLayoutChange = (layout, layouts) => {
        saveToLocalStorage("layouts_" + identifier, layouts, identifier);
        setLayoutsOnState(layouts);
    }

    const resetLayout = () => {
        console.log({ originalLayout });
        setLayoutsOnState(originalLayout);
    }

    const beanData = (unbeandedData) => {

        let parsedLayouts = {
            lg: [],
            xs: []
        };
        unbeandedData.map(group => {
            let serverLayout = group.layout;
            if (serverLayout.lg && serverLayout.xs) {
                parsedLayouts.lg = [ ...parsedLayouts.lg, serverLayout.lg ];
                parsedLayouts.xs = [ ...parsedLayouts.xs, serverLayout.xs ];
            }
        })

        console.log(parsedLayouts);

        // Salvamos o layout original para um possivel reset
        setOriginalLayout({ ...parsedLayouts });

        const layouts = (recoveredLayouts && Object.keys(recoveredLayouts).length > 0)
            ? recoveredLayouts
            : parsedLayouts;

        console.log({ layouts })

        setData(unbeandedData);
        setLayoutsOnState(layouts);
    }

    const loadData = () => {
        setIsLoading(true);

        const dateRangeValue = (dateRange && dateRange[0] && dateRange[1]) ? [
            dayjs(dateRange[0]).format('YYYY-MM-DD HH:mm:ss'),
            dayjs(dateRange[1]).format('YYYY-MM-DD HH:mm:ss')
        ] : defaultDate;

        return get(`${endpoint}&dateRange=${dateRangeValue}`)
            .then((response) => {
                if(response.status == 200) {
                    const data = response.data;
                    beanData(data);
                };
            })
            .finally(() => setIsLoading(false))
    }

    useEffect(() => {
        loadData();
    }, []);

    return <div className="dashboard">
        <div className="action-dashboard-container">

            <DateRangePicker
                ranges={predefinedBottomRanges}
                value={dateRange}
                onChange={setDateRange}
                showMeridian
                format="dd/MM/yyyy HH:mm:ss"
                defaultCalendarValue={defaultDate}
            />

            <button onClick={loadData} className="btn btn-sm btn-success">Aplicar</button>
            <button onClick={resetLayout} className="btn btn-sm btn-info">Reset</button>
        </div>

        { !isLoading && <ResponsiveGridLayout
            className="layout"
            rowHeight={30}
            cols={{ lg: 24, xs: 1 }}
            margin={[10,10]}
            onLayoutChange={onLayoutChange}
            layouts={layoutsOnState}
            breakpoints={{ lg: 1000, xs: 0 }}
            draggableHandle={".move-handle"}
            useCSSTransforms={false}
        >
            {
                data.map((group, i) => {
                    return  <div
                        className={"dashboard-item dashboard-item-" + group.type}
                        key={i + 1}
                    >
                        <div className={"dashboard-item-title"} onClick={() => alert(JSON.stringify(layoutsOnState.lg[i]))}>
                            <div className={"move-handle"}>
                                <Move size={11} />
                            </div>
                            {group.title}
                        </div>
                        <div className={"dashboard-item-body"}>
                            {/*{ JSON.stringify(layoutOnState[i], null, 2) }*/}
                            <Indicador group={group} index={i} />
                        </div>
                    </div>
                })
            }
        </ResponsiveGridLayout> }

        <Overlay show={isLoading} />
    </div>

}

const Indicador = ({ group, index }) => {
    switch (group.type) {
        case 'linechart':
            return <LineChart key={index} group={group} />;
        case 'timeseries':
            return <TimeSeriesChart key={index} group={group} />;
        case 'bar':
            return <BarChart key={index} group={group} />;
        case 'metric':
            return <MetricChart key={index} group={group} />;
        case 'table':
            return <TableChart key={index} group={group} />;
        default:
            return <>{group.layout.i} - {group.type} - {group.title}</>;
    }
};

export default Dashboard;