import React, {createContext, useState, useContext, useEffect} from 'react';
import {Outlet} from "react-router-dom";
import AuthProvider from "../services/AuthProvider";

import {Box, FileText, Home as HomeIcon, List, Menu, Tablet, User, Users, X, Zap} from 'react-feather';

import { CustomProvider } from 'rsuite';
import ptBR from 'rsuite/locales/pt_BR';
import 'rsuite/dist/rsuite.min.css';

import './Layout.scss'

import {ReactComponent as Logo} from '../assets/logo.svg';
import classNames from "classnames";

import {useForm, FormProvider} from "react-hook-form";
import UiSelectPicker from "../components/Form/UiSelectPicker";

import ClienteForm from "../pages/ClienteForm";
import OrcamentoForm from "../pages/OrcamentoForm";
import VendedorForm from "../pages/VendedorForm";
import Home from "../pages/Home";
import NewAbstractGrid from "./Core/NewAbstractGrid";
import KitForm from "../pages/KitForm";
import ContratoForm from "../pages/ContratoForm";
import KitAdd from "../pages/KitAdd";
import Neto from "../pages/Neto";
import { v4 as uuidv4 } from 'uuid';
import {LogOut} from 'react-feather';
import OrcamentoRapido from "../pages/OrcamentoRapido";
import DashboardVendedores from "../pages/DashboardVendedores";

export const menus = [
    {
        name: 'Home',
        icon: <HomeIcon/>,
        component: React.memo(Home),
        props: {},
        allowed: [1]
    },
    {
        name: 'Dashboard',
        icon: <HomeIcon/>,
        component: React.memo(DashboardVendedores),
        props: {},
        allowed: [2,3]
    },
    {
        name: 'Clientes',
        icon: <Users/>,
        component: React.memo(NewAbstractGrid),
        props: {router: 'cliente', formComponent: ClienteForm},
        allowed: [1, 2, 3]
    },
    {
        name: 'Orçamentos',
        icon: <List/>,
        component: React.memo(NewAbstractGrid),
        props: {router: 'orcamento', formComponent: OrcamentoForm},
        allowed: [1, 2, 3]
    },
    {
        name: 'Kits',
        icon: <Tablet/>,
        component: React.memo(NewAbstractGrid),
        props: {router: 'kit', formComponent: KitForm},
        allowed: [1]
    },
    {
        name: 'Vendedores',
        icon: <User/>,
        component: React.memo(NewAbstractGrid),
        props: {router: 'vendedor', formComponent: VendedorForm},
        allowed: [1]
    },
    // {name: 'Alteração Kits', icon: <Tablet/>, component: React.memo(NewAbstractGrid), props: {router: 'kitlog'}},
    {
        name: 'Obras / Contratos',
        icon: <FileText/>,
        component: React.memo(NewAbstractGrid),
        props: {router: 'contrato', formComponent: ContratoForm},
        allowed: [1]
    },
    // {
    //     name: 'Celesc',
    //     icon: <Box/>,
    //     component: React.memo(Celesc),
    //     props: {},
    //     allowed: [1]
    // },
    {
        name: 'Fornecedores',
        icon: <Box/>,
        component: React.memo(KitAdd),
        props: {},
        allowed: [1]
    },

    {
        name: 'Neto',
        icon: <Box/>,
        component: React.memo(Neto),
        props: {},
        allowed: [1],
        allowedUser: [3]
    },

    {
        name: 'Orçamento Rápido',
        icon: <Zap/>,
        component: React.memo(OrcamentoRapido),
        props: {},
        allowed: [1, 2, 3]
    },

];

const LayoutContext = createContext(null);

export const LayoutProvider = ({children}) => {
    const [tabs, setTabs] = useState([]);
    const [activeTab, setActiveTab] = useState();
    const [showMenu, setShowMenu] = useState(false);
    const [tabsRefs, setTabsRefs] = useState([]);

    const toggleMenu = () => setShowMenu((prevState) => !prevState);

    const closeTab = React.useCallback((menu) => {
        const newTabs = [...tabs].filter((tab) => {
            return tab.uuid !== menu.uuid
        });
        const lastTab = newTabs.at(-1);
        if(lastTab) {
            setActiveTab(lastTab.uuid);
        }
        setTabs(newTabs);
    }, [tabs]);

    const openMenu = React.useCallback((item, forceNew = false) => {
        let exists = null;
        tabs.map((tabItem, tabIndex) => {
            if (tabItem.name == item.name) {
                exists = tabItem.uuid;
            }
        });

        if (exists && !forceNew) {
            setActiveTab(exists);
        } else {
            const newTab = {
                name: item.name,
                icon: item.icon,
                component: item.component,
                uuid: uuidv4(),
                props: {
                    ...item.props
                }
            };

            const newTabs = [...tabs, newTab];
            setTabs(newTabs);
            setActiveTab(newTab.uuid);
        }
    }, [tabs]);

    return (
        <LayoutContext.Provider value={{
            tabs, activeTab, setActiveTab,
            menus, showMenu, toggleMenu, openMenu, closeTab,
            tabsRefs, setTabsRefs
        }}>
            {children}
        </LayoutContext.Provider>
    );
}

export const withLayout = (Child) => (props) => (
    <LayoutContext.Consumer>
        {(context) => <Child {...props} {...context} />}
    </LayoutContext.Consumer>
);



const UserInfo = () => {
    const { triggerLogout, user } = useContext(AuthProvider);

    return <div className="user-info">
        <div className="user-name">{user.nome}</div>
        <button onClick={() => triggerLogout()}><LogOut /></button>
    </div>
}

const LayoutView = (LayoutProps) => {
    const {showMenu, toggleMenu, menus, tabs, activeTab, setActiveTab, openMenu, closeTab, tabsRefs, setTabsRefs} = LayoutProps;
    const { user } = useContext(AuthProvider);

    // const methods = useForm();
    // const allData = methods.watch();

    useEffect(() => {
        openMenu(menus[0]);
    }, []);

    return <>
        <nav id="sidebarMenu" className={"sidebar " + (showMenu ? 'menu-show' : '')}>
            <header>
                <div className="logo">
                    <Logo/>
                    <div className="logo-typo">
                        <b>Argon</b><br/>Solar
                    </div>
                </div>
                <button onClick={toggleMenu}><Menu/></button>
            </header>

            <ul className="nav">
                {
                    menus && menus.map((item, itemIdx) => {
                        if(item.allowed){
                            if(user){
                                if(!item.allowed.includes(user?.acesso)){
                                    return null;
                                }
                            }
                        }
                        if(item.allowedUser){
                            if(user){
                                if(!item.allowedUser.includes(user?.id)){
                                    return null;
                                }
                            }
                        }
                        return <li key={itemIdx} className="nav-item">
                                <a className="nav-link" href="#" onClick={() => openMenu && openMenu(item)}>
                                    {item.icon} {item.name}
                                </a>
                            </li>
                        }
                    )
                }
            </ul>

            <UserInfo />
        </nav>
        <main>

            {/*<FormProvider {...methods} >*/}
            {/*    <UiSelectPicker size={12} name="tag2" label="Tags" router="tag" idField="id" titleField="titulo"/>*/}
            {/*    <pre>{JSON.stringify(allData, null, 2)}</pre>*/}
            {/*</FormProvider>*/}

            <nav className="nav nav-tabs">
                {tabs && tabs.map((tab) => {
                    return (
                        <div key={tab.uuid}
                             onMouseDown={(event) => {
                                 if (event.button == 1) {
                                     event.preventDefault();
                                     closeTab && closeTab(tab);
                                 }
                             }}
                             onClick={(event) => {
                                 setActiveTab && setActiveTab(tab.uuid);
                             }} className={classNames('nav-link', {
                            'active': activeTab == tab.uuid
                        })}>
                            {tab.name}
                            <div className="nav-link-close" onClick={(event) => {
                                event.stopPropagation();
                                closeTab && closeTab(tab)
                            }}>
                                <X/>
                            </div>
                        </div>
                    )
                })}
            </nav>
            <div className="tab-content">
                {tabs && tabs.map((tab) => {
                    const TabContentComponent = tab.component;
                    return (
                        <div key={'tab-content-' + tab.uuid} ref={(contentRef) => {
                            setTabsRefs && setTabsRefs((prevValue) => {
                                prevValue[tab.uuid] = contentRef;
                                return prevValue;
                            });
                        }} className={classNames('tab-pane', {
                            'show active': activeTab == tab.uuid
                        })} id={'tab-' + tab.uuid}>
                            <TabContentComponent {...tab.props} {...LayoutProps} />
                        </div>
                    )
                })}
            </div>
            <Outlet/>
        </main>
    </>
}

const LayoutWithContext = withLayout(LayoutView);

const Layout = () => {
    return (
        <CustomProvider locale={ptBR}>
            <LayoutProvider>
                <LayoutWithContext/>
            </LayoutProvider>
        </CustomProvider>
    );

}

export default Layout;