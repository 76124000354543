import * as dayjs from "dayjs";
import {Util} from "../../util/Util";

const formatter = function (val, format) {
    if(val) {
        switch (format) {
            case 'moeda':
                return Util.moeda(val);
                break
            case 'integer':
                return (val).toFixed(0);
                break
            case 'watt':
                return (val) + 'W';
                break
            case 'percent':
                return (val * 1).toFixed(2) + '%';
                break
            case 'dmy':
                return dayjs(val).format('DD/MM/YYYY');
                break
            case 'my':
                return dayjs(val).format('MM/YYYY');
                break
            case 'y':
                return dayjs(val).format('YYYY');
                break
            default:
                return val;
        }
    }
}

export { formatter };