import React, {useState, useContext} from 'react'

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import '../components/Core/AbstractGrid.scss';
import {useForm, FormProvider, useWatch} from "react-hook-form";
import UiInputText from "../components/Form/UiInputText";
import Form from "../components/Form/Form";
import "./OrcamentoRapido.scss";

import Overlay from "../components/Overlay";
import {Plus, Search} from "react-feather";
import UiInputList from "../components/Form/UiInputList";
import {get} from "../services/ApiService";
import {menus} from "../components/Layout";
import AuthProvider from "../services/AuthProvider";

const OrcamentoRapido = (props) => {
    const openMenu = props.openMenu;
    const [loading, setLoading] = useState(false);
    //const [data, setData] = useState();
    const [message, setMessage] = useState(null);

    const { user } = useContext(AuthProvider);

    const methods = useForm({
        defaultValues: props.data
    });
    const { handleSubmit, watch } = methods;
    const allData = watch();

    const doAction = () => {
        setMessage(null);
        setLoading(true);
        doRequest('consultarDados');
    }

    const getCredentials = () => {
        return {
            nrUC: allData.nrUC,
            nrDocumento: allData.nrDocumento,
        }
    }
    const doRequest = (action) => {
        get(`/?route=celesc&action=${action}&payload=${JSON.stringify(getCredentials())}`)
            .then((response) => {
                setLoading(false);
                if(response.status == 200) {
                    //setData(response.data);
                    if(response.data.cliente){
                        criarOrcamento(response.data);
                    }else{
                        setMessage("Erro de validacão. Verifique os dados digitados.");
                    }
                }
            })
            .catch((err) => {
                setLoading(false);
                setMessage(err);
            })
    }

    const criarOrcamento = (data) => {
        if(!data){
            alert('Não há dados para criar o orçamento.');
            return;
        }

        let menu = menus.find(item => {
           return item.name == "Orçamentos"
        });

        if(menu) {
            menu.props = {
                ...menu.props,
                ...{
                    onInitOpenData: {
                        engenheiro: 2,
                        ucList: data.cliente.ucList,
                        cliente: data.cliente,
                        //vendedor: { id: 1 }
                    }
                }
            }

            openMenu({
                ...menu
            }, true);
        }
    }

    return (
        <div className="simple-page-box">
            <Overlay show={loading} />

            <FormProvider {...methods} >
                <Form onSubmit={handleSubmit(props.triggerSubmit)} methods={methods}>
                    <div className="form-box">
                        <UiInputText size={12} name="nrUC" label="Unidade Consumidora"/>
                        <UiInputText size={12} name="nrDocumento" label="CPF/CNPJ"/>
                        <button onClick={doAction} className="btn btn-sm btn-success"><Search /> Criar orçamento</button>

                        <>
                            { message && (<div className="alert alert-info" style={{margin: '20px 0 0 0'}}>{message}</div>) }
                        </>

                    </div>

                </Form>
            </FormProvider>
        </div>
    );

}

export default OrcamentoRapido;