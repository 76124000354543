import React, {useEffect} from 'react'
import {useFormContext} from "react-hook-form";
import {InputNumber} from "primereact/inputnumber";

export default function UiInputNumberInteger({ label, name, size, asTable, ...rest }: any){
    const { register, setValue, watch } = useFormContext();
    const value = watch(name);

    useEffect(() => {
        register(name);
    }, [register]);

    return (
        <div className={`col-sm-${(size) ? size : 12}`}>
            <div className="form-item">
                { !asTable && <label className="form-label">{label}</label> }
                <div className="field-container">
                    <InputNumber
                        onChange={(e) => setValue(name, e.value)}
                        value={value}

                        {...rest}
                    />
                </div>
            </div>
        </div>
    )
}