import React, {useContext, useEffect} from 'react'
import UiInputText from "../components/Form/UiInputText";
import Form from "../components/Form/Form";
import {useForm, FormProvider} from "react-hook-form";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import AuthProvider from "../services/AuthProvider";
import UiInputNumber from "../components/Form/UiInputNumber";
import UiSelectPicker from "../components/Form/UiSelectPicker";

const acessoListBase: any = {
    1: 'Master',
    2: 'Vendedor',
    3: 'Supervisor',
};
const acessoList = Object.keys(acessoListBase).map((key) => {
    return { id: key, title: acessoListBase[key] };
})


export default function MovimentacaoForm(props: any){
    const { user } = useContext<any>(AuthProvider);

    const parentProps = props.parentProps;
    const methods = useForm({
        defaultValues: props.data
    });
    const { register, handleSubmit, watch, setValue } = methods;
    const allData = watch();

    return (
        <Modal centered size="sm" show={true} animation={false} onHide={props.triggerClose}>
            <FormProvider {...methods} >
                <Form onSubmit={handleSubmit(props.triggerSubmit)} methods={methods}>
                    <Modal.Body>
                        <div className="row">
                            <UiInputText size={12} name="descricao" label="Descrição"/>
                            <UiInputNumber size={2} register={register} name="valor" label="Preço"/>
                            <UiInputText size={12} name="data" label="Data"/>
                            <UiInputText size={12} name="competencia" label="Competencia"/>
                            <UiSelectPicker size={12} name="tag" label="Tags"
                                            extraDataOnCreate={{ tipo: 1 }}
                                            extraDataOnFilter={{
                                                "name": 'tipo',
                                                "v": '1',
                                                "v2": null,
                                                "op": 'like'
                                            }}
                                            router="tag" idField="id" titleField="titulo"/>
                        </div>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="secondary" onClick={props.triggerClose}>Fechar</Button>
                        <Button variant="primary" type="submit">Salvar</Button>
                    </Modal.Footer>
                </Form>
            </FormProvider>
        </Modal>
    )
}