import React from 'react';
import {ReactComponent as IconLoading} from "../assets/icon-loading.svg";
import {CSSTransition} from "react-transition-group";

import './Overlay.scss';

interface LoadingProps{
    show: boolean;
    children?: React.ReactChild | null;
}

export default function Overlay(props: LoadingProps){
    return (
        <CSSTransition unmountOnExit in={props.show} timeout={300} classNames="overlay">
            <div className="overlay">{ !props.children ? <IconLoading /> : props.children }</div>
        </CSSTransition>
    )
}