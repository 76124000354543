import React, {useEffect, useRef, useState} from 'react'

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import '../components/Core/AbstractGrid.scss';
import {useForm, FormProvider, useWatch} from "react-hook-form";
import UiInputText from "../components/Form/UiInputText";
import Form from "../components/Form/Form";

import Overlay from "../components/Overlay";
import {Plus, Search} from "react-feather";
import axios from "axios";
import UiInputList from "../components/Form/UiInputList";
import {get, post} from "../services/ApiService";
import {useNavigate} from "react-router-dom";
import UiInputTextarea from "../components/Form/UiInputTextarea";
import UiInputTextareaContent from "../components/Form/UiInputTextareaContent";
import UIInputFile from "../components/Form/UIInputFile";
import {Toast} from "primereact/toast";
import {menus} from "../components/Layout";
import {Util} from "../util/Util";
const HtmlTableToJson = require('html-table-to-json');

const strToDecimal = (str: string) => {
    return parseInt(str.replace(/([,\.])/g, '')) / 100;
}

export default function KitAdd(props: any) {
    //const navigate = useNavigate();
    const openMenu = props.openMenu;
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<any>();
    const [log, setLog] = useState<any>();
    const [message, setMessage] = useState(null);
    const methods = useForm({
        defaultValues: props.data
    });
    const { handleSubmit, watch, setValue } = methods;
    const toast = useRef(null);
    const actionList = [
        { id: 4, title: 'Converter Atinos para JSON', action: 'uploadAtinosToJson' },
        { id: 3, title: 'Importar/Atualizar kits from JSON', action: 'uploadFromJson' },

        //{ id: 2, title: 'Importar planilha da Atinos (.xlsx)', action: 'uploadAtinos' },

        { id: 1, title: 'Importar kit via PDF (OIW/BelEnergy/Vivensis)', action: 'uploadPdf' },

        { id: 5, title: 'Gerar JSON dos kits da SOOLLAR', action: 'gerarJsonSoollar' },
        { id: 6, title: 'Gerar JSON dos kits da ATINOS', action: 'gerarJsonAtinos' },
        { id: 7, title: 'Gerar JSON dos kits da MAXSUL', action: 'gerarJsonMaxsul' },
    ];

    const [ showProcessar, setShowProcessar ] = useState(false);
    const [ processarTotal, setProcessarTotal ] = useState(0);
    const [ processarAtual, setProcessarAtual ] = useState(0);
    const [ processarPages, setProcessarPages ] = useState(0);
    const [ importUrl, setImportUrl ] = useState('');
    const [ productList, setProductList ] = useState([]);

    const doAction = () => {
        const texto = watch('texto');
        const jsonTables = HtmlTableToJson.parse(texto ?? '');
        console.log(jsonTables);
        if(jsonTables.results && jsonTables.results.length > 0) {
            let localPayload: { [key: string]: any } = {};
            if (jsonTables) {
                localPayload.produtos = [];
                jsonTables.results.map((rootItem: any) => {
                    rootItem.map((item: any) => {
                        localPayload.produtos.push({
                            tipo: item.TIPO,
                            nome: item.PRODUTO,
                            quantidade: parseInt(item.QUANTIDADE)
                        })
                    })
                });
            }

            if (texto) {
                let textoLimpo = texto.replace(/(<([^>]+)>)/gi, "");

                const matchPotencia = texto.match(/Potência do kit: (.*) kWp/);
                if (matchPotencia) {
                    localPayload.potencia = strToDecimal(matchPotencia[1]);
                }
                const matchOrcamento = texto.match(/([0-9]{9})/);
                if (matchOrcamento) {
                    localPayload.codigoOrcamento = matchOrcamento[1];
                }
                const matchValor = textoLimpo.match(/Valor finalR\$ (.*)BAIXAR/);
                if (matchValor) {
                    localPayload.precoCusto = strToDecimal(matchValor[1]);
                }

                if(textoLimpo.search('Telha Colonial')){
                    localPayload.estrutura = 131;
                }
                if(textoLimpo.search('Telha Fibrometálica')){
                    localPayload.estrutura = 131;
                }
            }

            console.log(localPayload);
            setData(localPayload);

        }
    }

    const allData = watch();

    async function processarChunk(chunk: any, index: any){
        return post(`/?route=kit/form&action=uploadFromJson`, {
            kitList: JSON.stringify(chunk)
        });
    }

    async function processarDados(response: any){
        setShowProcessar(true);
        setProcessarTotal(response.length);

        const porPagina = 50;
        setProcessarPages(Math.ceil(response.length / porPagina));

        const chunkList = Util.sliceIntoChunks(response, porPagina);

        await chunkList.reduce(async (a: any, item: any, index: any) => {

            // Wait for the previous item to finish processing
            await a;

            // Process this item
            const response = await processarChunk(item, index);

            if(response.data.success != true){
                console.log('Erro no Chunk #' + index, response);
            }
            setProcessarAtual((prevState) => prevState + porPagina);

        }, Promise.resolve());
    }

    async function startImportFromFileUrl(){
        fetch(importUrl)
            .then((response) => response.json())
            .then((json) => processarDados(json));
    }

    const doActionUpload = () => {
        const doRequest = (action: string) => {
            setLoading(true);
            let data = allData;
            delete data.action;
            post(`/?route=kit/form&action=${action}`, data)
                .then((response: any) => {
                    setLoading(false);
                    if (response.status != 200) {
                        return Promise.reject('Falha ao carregar');
                    }
                    if (response.data.success != true) {
                        return Promise.reject(response.data.message);
                    }else{

                        if(response.data?.payload?.url){
                            setImportUrl(response.data?.payload?.url);
                        }

                        if(response.data?.payload?.productList){
                            setProductList(response.data?.payload?.productList);
                        }

                    }

                    setData(response.data.payload);
                    //setValue('texto', response.data.payload);
                    toastAlert({
                        severity: 'success',
                        summary: 'Sucesso!',
                        detail: response.data.message,
                        life: 3000
                    });
                })
                .catch((err) => {
                    console.log(err);
                    setLoading(false);
                    toastAlert({
                        severity: 'error',
                        summary: 'Erro!',
                        detail: err,
                        life: 3000
                    });
                })
        }

        const action = actionList.find((item) => item.id === parseInt(allData.action));
        if(action){
            setLoading(true);
            doRequest(action.action);
        }else{
            alert('Escolha uma ação');
        }
    }

    const doActionUpdate = () => {
        setLoading(true);
        let data = allData;
        delete data.action;
        post(`/?route=kit/form&action=updateAldo`, data)
            .then((response: any) => {
                setLoading(false);
                if(response.status != 200){
                    return Promise.reject('Falha ao carregar');
                }
                if(response.data.success != true){
                    return Promise.reject(response.data.message);
                }

                if(response.data.payload.log){
                    setLog(response.data.payload.log);
                }else {
                    setData(response.data.payload);
                }
                //setValue('texto', response.data.payload);
                toastAlert({
                    severity: 'success',
                    summary: 'Sucesso!',
                    detail: response.data.message,
                    life: 3000
                });
            })
            .catch((err) => {
                setLoading(false);
                toastAlert({
                    severity: 'error',
                    summary: 'Erro!',
                    detail: err,
                    life: 3000
                });
            })
    }

    const toastAlert = (payload: any) => {
        // @ts-ignore
        toast.current.show(payload);
    }

    const criarKit = () => {
        if(!data){
            toastAlert({
                severity: 'error',
                summary: 'Erro!',
                detail: 'Não há dados para criar o kit',
                life: 3000
            });
            return;
        }

        let menu = menus.find(item => {
            return item.name == "Kits"
        });

        if(menu) {
            menu.props = {
                ...menu.props,
                ...{
                    onInitOpenData: {
                        ...data
                    }
                }
            }

            openMenu({
                ...menu
            });
        }
        /*navigate('/kit', {
            state: {
                onInitOpenData: {
                   ...data
                }
            }
        });*/
    }

    return (
        <div className="simple-page">
            <Toast ref={toast} />
            <Overlay show={loading} />

            <FormProvider {...methods} >
                <Form onSubmit={handleSubmit(props.triggerSubmit)} methods={methods}>
                    <div className="row" style={{ height: 'calc(100vh - 115px)', maxWidth: 'calc(100vw - 20px)' }}>
                        <div className="col-sm-3">

                            <UIInputFile size={12}  name="pdf" label="Arquivo (pdf/xlsx)"/>
                            <UiInputList size={12} name="action" label="Ação" list={actionList} idField="id" titleField="title" />
                            { (allData.action > 1 && allData.action < 5) && <UiInputText size={12}  name="percentualDesconto" label="Desconto"/> }
                            { (allData.action > 5) && <UiInputText size={12}  name="minPlacas" label="Minimo de Placas"/> }
                            { (allData.action > 5) && <UiInputText size={12}  name="maxPlacas" label="Máximo de Placas"/> }
                            <button onClick={doActionUpload} className="btn btn-sm btn-success"><Search /> Enviar PDF</button>

                            { /*
                            <UiInputTextareaContent size={12}  name="texto" label="Dados" style={{ height: 300, overflow: 'auto' }}/>
                            <button onClick={doAction} className="btn btn-sm btn-success"><Search /> Processar</button> */ }

                            <hr />

                            <UiInputText size={12}  name="referenciaAldo" label="Referencia na Aldo"/>
                            <button onClick={doActionUpdate} className="btn btn-sm btn-success"><Search /> Atualizar kit</button>

                        </div>
                        <div className="col-sm-9">
                            { showProcessar && <>
                                <b>{processarTotal}</b> registros, processando:<br />
                                Atual: <b>{processarAtual}</b><br />
                                Páginas: <b>{processarPages}</b>
                                <hr />
                            </>}
                            { data && allData.action != 5 && <button onClick={criarKit} className="btn btn-sm btn-success"><Plus /> Criar kit</button> }
                            { importUrl && <button onClick={startImportFromFileUrl} className="btn btn-sm btn-success"><Plus /> Iniciar importação</button> }
                            { productList && productList.length > 0 && <button onClick={() => processarDados(productList)} className="btn btn-sm btn-success"><Plus /> Iniciar importação</button> }
                            { message && (<div className="alert alert-info">{message}</div>) }
                            {/*{ data && allData.action != 5 && (<pre>{JSON.stringify(data, null, 2)}</pre>) }*/}
                            { log && (<pre>{log}</pre>) }
                        </div>
                    </div>
                </Form>
            </FormProvider>


        </div>
    );

}

const Processar = (props: any) => {
    return <>
        { JSON.stringify(props, null, 2) }
    </>
}