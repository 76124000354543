import React, { createContext, useState } from 'react';
import {get, post} from "./ApiService";

const AuthContext = createContext({});

export default AuthContext;

export const AuthProvider = ({ children }) => {
    const [ user, setUser ] = useState(null);

    async function initLogin(onSuccess = (data) => {}, onError = (data) => {}) {
        const response = await get('/?route=login&action=session');
        if(response.data.user){
            setUser(response.data.user);
            onSuccess(response.data);
        }else{
            onError(response.data);
        }

    }

    async function triggerLogin(authData, onSuccess = (data) => {}, onError = (data) => {}) {
        const response = await post('/?route=login&action=login', authData);

        if(response.data.success){
            setUser(response.data.user);
            //api.defaults.headers.Authorization = `Bearer ${response.data.token}`
            onSuccess(response.data);
        }else{
            onError(response.data);
        }

    }

    async function triggerLogout(authData) {
        await post('/?route=login&action=logout', authData);
        setUser(null);
        //api.defaults.headers.Authorization = `Bearer ${response.data.token}`
    }

    return (
        <AuthContext.Provider value={{ isLoggedIn: Boolean(user), user, triggerLogin, triggerLogout, initLogin }}>
            {children}
        </AuthContext.Provider>
    );
};