import React from 'react'
import {FormContext} from "../../pages/OrcamentoForm";
import {useFormContext} from "react-hook-form";

export default function UiInputText({ label, name, size, linkComponent, asTable, ...rest }: any){
    const formContext = useFormContext();
    const { register } = formContext;
    const LinkComponent = linkComponent as JSX.Element;

    return (
        <div className={`col-sm-${(size) ? size : 12}`}>
            <div className="form-item">
                { !asTable &&
                    <label className="form-label">{label}{
                        LinkComponent &&
                        // @ts-ignore
                        <LinkComponent formContext={formContext} name={name}/>
                    }
                    </label>
                }
                <div className="field-container">
                    <input type="text" className="form-control" {...register(name)} {...rest} />
                </div>
            </div>
        </div>
    )
}