import React, {useContext, useState} from 'react'
import {Search} from "react-feather";
import Modal from 'react-bootstrap/Modal';
import AbstractGrid from "../Core/AbstractGrid";
import {FormContext} from "../../pages/OrcamentoForm";
import Button from "react-bootstrap/Button";
import {useFormContext} from "react-hook-form";
import NewAbstractGrid from "../Core/NewAbstractGrid";

export default function UiInputSuggest({ label, name, idField, titleField, size, router, onSelect, initialFilter, formComponent, extraData, ...rest }: any){
    const [showGrid, setShowGrid] = useState(false);
    const { setValue, register, watch } = useFormContext();
    const value = watch(name);

    const Grid = () => {
        return <Modal fullscreen={true} centered show={true} animation={false} onHide={closeGrid}>
            <Modal.Body>
                <NewAbstractGrid
                    selected={value}
                    selectedIndex={{[value[idField]]: true}}
                    initialFilter={initialFilter}
                    extraData={extraData}
                    router={router}
                    onSelect={triggerSelect}
                    formComponent={formComponent}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button type="button" variant="secondary" onClick={closeGrid}>Fechar</Button>
            </Modal.Footer>
        </Modal>
    }

    const triggerSelect = (data: any) => {
        const originalData = data;
        Object.keys(originalData).map((keyItem: any) => {
            if(keyItem.indexOf('/') != -1){
                var exploded = keyItem.split('/');
                if(!data[exploded[0]]){
                    data[exploded[0]] = {};
                }
                data[exploded[0]][exploded[1]] = data[keyItem];
            }
        });

        setValue(`${name}`, data);
        if(onSelect){
            onSelect(data);
        }
        closeGrid();
    }

    const closeGrid = () => {
        setShowGrid(false);
    }
    const openGrid = () => {
        setShowGrid(true);
    }

    return (
        <div className={`col-sm-${(size) ? size : 12}`}>
            <div className="form-item">
                <label className="form-label">{label}</label>
                <div className="field-container">
                    { showGrid && <Grid /> }
                    <div className="d-flex align-items-center">
                        <input type="text" style={{width: '10%', minWidth: '70px'}} className="form-control"
                               {...register(`${name}.${idField}`)} {...rest} />
                        <button type="button" className="btn btn-xs btn-primary mx-2" onClick={() => openGrid() } {...rest}><Search strokeWidth={2} size={16} /></button>
                        <input type="text" className="form-control"
                               {...register(`${name}.${titleField}`)} {...rest} />
                    </div>
                </div>
            </div>
        </div>
    )
}
