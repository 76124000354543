import React from "react";
import { useForm } from "react-hook-form";

export default function Form({ methods, children, ...rest }: any) {

    return (
        <form { ...rest }>
            {React.Children.map(children, child => {
                return child.props.name
                    ? React.createElement(child.type, {
                        ...{
                            ...child.props,
                            register: methods.register,
                            key: child.props.name
                        }
                    })
                    : child;
            })}
        </form>
    );
}
