import React, {useContext} from 'react'
import {useFormContext} from "react-hook-form";

export default function UiInputTextarea({ label, name, size, linkComponent, ...rest }: any){
    const formContext = useFormContext();
    const { register } = formContext;
    const LinkComponent = linkComponent as JSX.Element;

    return (
        <div className={`col-sm-${(size) ? size : 12}`}>
            <div className="form-item">
                <label className="form-label" onClick={(event) => event.preventDefault()}>{label}{
                    LinkComponent &&
                    // @ts-ignore
                    <LinkComponent formContext={formContext} name={name}/>
                }</label>
                <div className="field-container">
                    <textarea className="form-control" {...register(name)} {...rest} />
                </div>
            </div>
        </div>
    )
}