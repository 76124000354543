import React, {useEffect, useRef, useState} from 'react'
import {useFormContext} from "react-hook-form";
import CreatableSelect  from 'react-select/creatable';
import { AsyncCreatableSelect as AsyncCreatable } from '@atlaskit/select';
import Spinner from '@atlaskit/spinner';

import {post} from "../../services/ApiService";

const LoadingIndicator = (props) => {
    return <Spinner {...props} />;
};

const customStyles = {
    control: (provided, state) => ({
        ...provided,
        padding: '0',
        margin: '0',
        borderColor: '#ced4da',
        backgroundColor: '#FFF',
        boxShadow: 'none',
        fontSize: '12px',
        minHeight: '30px',
        height: '30px',
        '&:hover': {
            borderColor: '#ced4da', // Altera a cor da borda ao passar o mouse
        },
    }),
    valueContainer: (provided) => ({
        ...provided,
        padding: '0', // Personaliza o espaçamento interno do valor selecionado
        margin: '0',
    }),
    input: (provided) => ({
        ...provided,
        padding: '0', // Define o espaçamento interno do texto digitado
        margin: '0',
    }),
    indicatorsContainer: (provided) => ({
        ...provided,
        margin: '0',
        padding: '0',
        height: '28px', // Ajuste a altura dos indicadores (seta e ícones)
    }),
};

const useData = (router, idField, titleField, extraDataOnFilter) => {
    const [isLoading, setIsLoading] = useState(false);
    const [options, setOptions] = useState([]);

    const transformDataToSelect = (item) => {
        return {
            label: item[titleField],
            value: item[idField],
        }
    }

    const fetchOptions = (filterValue) => {
        // setIsLoading(true);
        return new Promise((resolve, reject) => {
            console.log(extraDataOnFilter);
            const filter = filterValue ? JSON.stringify([
                {
                    "name": titleField,
                    "v": filterValue,
                    "v2": null,
                    "op": 'like'
                },
                extraDataOnFilter
            ]) : JSON.stringify([ extraDataOnFilter ]);
            console.log(filter)

            fetch(`https://api.argonsolar.com.br/?route=${router}/grid&action=list&skip=0&limit=20&filter=${filter}`)
                .then(response => response.json())
                .then(responseData => {
                    resolve(responseData.rows.map(item => {
                        return transformDataToSelect(item)
                    }))
                })
                .catch(err => reject(err))
                .finally(() => {
                    setIsLoading(false)
                });
        });
    };

    const createOption = (data) => {
        return post(`/?route=${router}/form&action=add`, {dados: data})
            .then((response) => {
                if (response.status === 200) {
                    if (response.data.success) {
                        return Promise.resolve(transformDataToSelect(response.data.model));
                    }else{
                        return Promise.reject(response.data.message);
                    }
                } else {
                    return Promise.reject('Falha ao carregar');
                }
            })
            .catch((err) => {
                console.log(err)
            })
            .finally(() => setIsLoading(false))
    }

    return [fetchOptions, createOption, options, setOptions, isLoading, setIsLoading];
};

export default function UiSelectPicker({ label, name, size, idField, titleField, extraDataOnCreate, extraDataOnFilter, router,...rest }){
    const { register, setValue, watch } = useFormContext();
    const value = watch(name);
    const [fetchOptions, createOption, options, setOptions, isLoading, setIsLoading] = useData(router, idField, titleField, extraDataOnFilter);

    const handleCreateOption = (inputValue) => {
        setIsLoading(true);
        createOption({
            [titleField]: inputValue,
            ...extraDataOnCreate
        }).then((newOption) => {
            setIsLoading(false);
            setOptions((prev) => [...prev, newOption]);
            setValue(name, [...value, newOption]);
        });
    };

    useEffect(() => {
        register(name);
    }, [register]);

    return (
        <div className={`col-sm-${(size) ? size : 12}`}>
            <div className="form-item">
                <label className="form-label">{label}</label>
                <div className="field-container">

                    <AsyncCreatable
                        isMulti
                        loadOptions={fetchOptions}
                        defaultOptions={true}
                        cacheOptions
                        allowCreateWhileLoading={false}
                        onCreateOption={handleCreateOption}
                        placeholder="Pesquisar"
                        loadingMessage={() => <>Carregando</> }
                        noOptionsMessage={() => <>Nenhuma opção</> }
                        styles={customStyles}
                        value={value}
                        components={{ LoadingIndicator }}
                        onChange={(newValue) => {
                            setValue(name, newValue);
                        }}
                    />

                    {/*<CreatableSelect*/}
                    {/*    // isDisabled={isLoading}*/}
                    {/*    // isLoading={isLoading}*/}

                    {/*    onChange={(newValue) => setValue(name, newValue)}*/}
                    {/*    onCreateOption={handleCreateOption}*/}
                    {/*    loadOptions={fetchOptions}*/}

                    {/*    // isClearable*/}
                    {/*    isMulti*/}
                    {/*    cacheOptions*/}

                    {/*    defaultOptions={options}*/}
                    {/*    // options={options}*/}
                    {/*    // value={value}*/}
                    {/*/>*/}
                </div>
            </div>
        </div>
    )
}