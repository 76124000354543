import React, {useEffect} from 'react'
import {useFormContext} from "react-hook-form";
import {Calendar} from "primereact/calendar";
import {addLocale} from "primereact/api";
import moment from "moment";
import {DatePicker} from "@atlaskit/datetime-picker";
import { parseISO } from 'date-fns';

function UiInputDate({ label, name, size, ...rest }: any){
    const { register, setValue, watch } = useFormContext();
    let value = watch(name);
    if(value && !(value instanceof Date)){
        let newDate = moment(value);
        if(newDate.isValid()) {
            value = newDate.toDate();
        }else{
            let newDate = moment(value, 'DD/MM/YYYY');
            if(newDate.isValid()) {
                value = newDate.toDate();
            }else{
                let newDate = moment('15/'+value, 'DD/MM/YYYY');
                if(newDate.isValid()) {
                    value = newDate.toDate();
                }
            }
        }
    }

    useEffect(() => {
        register(name);
    }, [register]);

    addLocale('pt_BR', {

        //closeText: 'Fechar',
        // prevText: 'Anterior',
        // nextText: 'Próximo',
        monthNames: ['Janeiro','Fevereiro','Março','Abril','Maio','Junho','Julho','Agosto','Setembro','Outubro','Novembro','Dezembro'],
        monthNamesShort: ['Jan','Fev','Mar','Abr','Mai','Jun', 'Jul','Ago','Set','Out','Nov','Dez'],
        dayNames: ['Domingo','Segunda','Terça','Quarta','Quinta','Sexta','Sábado'],
        dayNamesShort: ['Dom','Seg','Ter','Qua','Qui','Sex','Sáb'],
        dayNamesMin: ['D','S','T','Q','Q','S','S'],
        weekHeader: 'Semana',
        // firstDay: 1,
        // isRTL: false,
        // showMonthAfterYear: false,
        // yearSuffix: '',
        // timeOnlyTitle: 'Só Horas',
        // timeText: 'Tempo',
        // hourText: 'Hora',
        // minuteText: 'Minuto',
        // secondText: 'Segundo',
        // currentText: 'Data Atual',
        // ampm: false,
        // month: 'Mês',
        // week: 'Semana',
        // day: 'Dia',
        // allDayText : 'Todo Dia'
    });

    return (
        <div className={`col-sm-${(size) ? size : 12}`}>
            <div className="form-item">
                <label className="form-label">{label}</label>
                <div className="field-container">
                    <Calendar
                        onChange={(e) => { setValue(name, e.value) }}
                        value={value}
                        locale="pt_BR"
                        {...rest}
                    />

                    {/*<DatePicker
                        onChange={(e) => { setValue(name, e) }}
                        value={value}
                        dateFormat="MM/YYYY"
                        locale="pt-BR"
                        parseInputValue={(date: string) => moment(date, 'MM/YYYY').toDate()}
                    />*/}

                    {/*<DatePicker
                        format="MM/yyyy"
                        ranges={[]}
                        onChange={(e) => { setValue(name, e) }}
                        value={value}
                    />*/}
                </div>
            </div>
        </div>
    )
}

export default React.memo(UiInputDate)